@import "src/assets/styles/var";

.auth-container {
  min-height: 100vh;

  .auth-img {
    width: 90%;
  }

  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: map-get($colors, neutrals-bg);
  }

  .auth-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: map-get($colors, neutrals-bg);
  }
}
