@import "src/assets/styles/var";
.container-results-demand-customer {
  margin-top: rem(8);

  height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  &-card {
    flex: 1;
    overflow-y: auto;
  }
}
.filter {
  width: 11rem;
}
.line-selects {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: rem(18);
  margin-bottom: rem(10);
  .element-count {
    font-size: rem(16);
    font-weight: 600;
    padding-top: rem(12);
    padding-left: rem(8);
  }
  .right-filters {
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    width: auto;
  }
}
.checkbox-selectall {
  margin-left: 2rem;
  cursor: pointer;
  margin-top: rem(12);
  margin-right: rem(32);
  display: flex;
  gap: rem(12);
  .text-selectall {
    font-size: rem(18);
    font-weight: 500;
  }
}
.flex-center {
  display: flex;
  justify-content: space-between;
  width: 27%;
  min-width: rem(350);
  margin-left: 2%;
}
.btn-height {
  height: rem(40);
}
.d-line {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flex-end {
  justify-content: end;
}
.d-none {
  display: none;
}
.btn-line-width {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: rem(8);
}
.line-single {
  width: auto;
  display: flex;
  gap: rem(0);
}

.max-content {
  width: max-content;
}

@media screen and (max-width: 1370px) {
  .line-single {
    width: auto;
    flex-wrap: wrap;
    gap: rem(8);
  }
  .right-filters {
    flex-direction: column;
    align-items: end;
    width: auto;
  }
  .checkbox-selectall {
    margin-right: 0;
    min-width: 10rem;
  }
}
