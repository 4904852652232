@import "src/assets/styles/var";

.mobile-container {
  min-height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    display: none;
  }

  .login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.title {
  margin-top: rem(32);
  margin-bottom: rem(16);
  @include font-style(24, 28, 700);
  color: map-get($colors, neutrals-on-bg);
}

.description {
  @include font-style(16, 24, 400);
  color: map-get($colors, neutrals-on-bg);
  text-align: center;
}

.desktop-layout,
.tablet-layout {
  display: none;
}

.desktop-layout {
  @media (min-width: 1024px) {
    display: block;
  }
}

.mobile-layout {
  @media (min-width: 768px) {
    display: none;
  }
}

.tablet-layout {
  @media (min-width: 768px) {
    display: block;
  
    .title {
      @include font-style(32, 38, 700);
    }
  }
}