@import "src/assets/styles/var";

.container-image-consultant-form {
  position: absolute;
  width: 23% !important;
  top: rem(51);

  .container-body {
    background-color: white;
    border-radius: rem(8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: rem(155);
    .image-consultant {
      width: rem(60);
      height: rem(60);
      object-fit: cover;
      border-radius: 50%;
    }

    .load-image-consultant {
      @include font-style(14, 24, 500);
      font-family: "Satoshi";
      color: map-get($colors, neutrals-on-bg);
    }
  }
}
