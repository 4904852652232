.empty-container {
    align-items: center;
    margin-bottom: rem(40);
  
    &.fill-container {
      height: calc(100vh - 292px);
    }
  
    .add-users-img {
      max-width: rem(260);
    }
  }