@import "src/assets/styles/var";

.wysiwing-container .angular-editor {

  .angular-editor-toolbar {
    background-color: transparent;
    border: 0;
    padding: 0;

    .angular-editor-toolbar-set .angular-editor-button {
      border: 0 !important;
      padding: rem(12) !important;
      border-radius: rem(4);

      &.active {
        background-color: map-get($primary, primary10) !important;
      }

      .fa {
        width: rem(16);
        height: rem(16);
        color: map-get($grey, grey500);
      }
    }

    .select-heading,
    .select-font,
    .select-font-size {
      width: rem(172) !important;

      &:hover {
        background-color: transparent !important;
      }

      .ae-picker,
      .ae-picker-label {
        border-radius: rem(8);
        background-color: map-get($colors, neutrals-bg);
      }

      .ae-picker {
        svg {
          display: none;
        }

        &.ae-expanded .ae-picker-label::before {
          content: '\e90a';
        }
      }

      .ae-picker-label {
        border: 0;
        padding: rem(8) rem(16);
        @include font-style(14, 24, 400);
        color: map-get($colors, neutrals-on-bg);

        &::before {
          background: map-get($colors, neutrals-bg) !important;
          content: '\e907';
          font-family: smartbeat;
          @include font-style(24, 24);
          right: rem(8);
          display: flex;
          align-items: center;
        }
      }

      .ae-picker-options {
        border: 0;
        margin-top: 0;
        border-radius: rem(8);
        overflow: hidden;

        .ae-picker-item  {
          padding: rem(8) rem(16);
          @include font-style(16, 24, 400);
          color: map-get($colors, neutrals-on-bg);

          &:hover,
          &.selected {
            background-color: map-get($primary, primary10);
          }

          &.selected::after {
            font-family: smartbeat;
            content: '\e90c';
            position: absolute;
            right: rem(8);
            font-size: rem(20);
          }
        }
      }
    }
  }

  .angular-editor-textarea {
    border: 0 !important;
    border-radius: rem(12);
    padding: rem(32) rem(20) !important;
    background-color: map-get($colors, neutrals-bg) !important;
    color: map-get($colors, neutrals-on-bg) !important;
    line-height: rem(18) !important;
    resize: none !important;

    &::after {
      content: none !important;
    }
  }
}