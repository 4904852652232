@import "src/assets/styles/var";

.container-slider-images {


  .arrow {
    width: rem(40);
    height: rem(40);
    cursor: pointer;
  }

    .counter {
        @include font-style(20, 24, 700);
        color: map-get($colors, neutrals-on-bg );
        text-align: center;
        margin-bottom:  rem(40);
    }

    .container-swiper {
      margin-bottom: rem(40);
    }

  .container-slide-image {
    display: flex;
    justify-content: center;
    height: calc(100vh - 330px);

    .slide-image {
      height: 100%;
      border-radius: rem(8);
      object-fit: cover;
    }
  }

  .swiper-image {
    height: rem(170);
    width: 100%;
    border-radius: rem(8);
    object-fit: cover;
  }

}

