@import "src/assets/styles/var";

.table-container {
  border-radius: rem(12);

  table {
    width: 100%;
    border-radius: rem(12);
    margin-bottom: rem(86);
  }

  &.groups-table {
    border-radius: 0;

    tr:first-child th:first-child {
      border-top-left-radius: 0;
    }
    tr:first-child th:last-child {
      border-top-right-radius: 0;
    }
    tr:last-child td:first-child {
      border-bottom-left-radius: 0;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 0;
    }

    table {
      margin-bottom: 0;
      border-radius: 0;
    }
  }

  tr:first-child th:first-child {
    border-top-left-radius: rem(12);
  }
  tr:first-child th:last-child {
    border-top-right-radius: rem(12);
  }
  tr:last-child td:first-child {
    border-bottom-left-radius: rem(12);
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: rem(12);
  }

  tr.mat-header-row {
    height: rem(36);
  }

  th.mat-header-cell {
    border: 0;
    @include font-style(12, 16, 700);
    color: map-get($colors, neutrals-on-bg);
  }

  .mat-sort-header-arrow {
    color: map-get($colors, neutrals-on-bg);
    font-size: rem(16);
  }

  .mat-sort-header-arrow {
    .mat-sort-header-indicator {
      color: map-get($colors, neutrals-on-bg);
      font-size: rem(16);
    }
  }

  tr.mat-row {
    height: rem(52);
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: map-get($grey, grey20);
    }

    &.highlight {
      background-color: map-get($primary, primary10);
    }

    &:last-child {
      border-radius: rem(12);
    }

  }

  tbody {
    tr:last-child {
      border-bottom-left-radius: rem(12);
      border-bottom-left-radius: rem(12);
    }
  }

  tr:last-child td {
    border-bottom: none;
  }

  .mat-cell,
  .mat-footer-cell {
    @include font-style(14, 18);
    color: map-get($colors, neutrals-on-bg);
    border-bottom-color: map-get($grey, grey50);
  }

  .mat-cell {

    .name-info {
      display: flex;
      align-items: center;
    }

    .name-value {
      font-weight: 700;
    }

    .actions-table-btn {
      padding: 0;
      width: rem(40);
      height: rem(40);

      .icon-more {
        font-size: rem(20);
        color: map-get($colors, neutrals-on-bg);
      }
    }

    .group {
      padding: rem(4) rem(8);
      @include font-style(10, 12, 700);
      border-radius: rem(4);
      background-color: map-get($primary, primary30);
      color: map-get($colors, neutrals-on-bg);
      margin-right: rem(8);
    }
  }

  .mat-table {
    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
      padding: 0 rem(16);
    }

    .mat-column-actions {
      text-align: right;
    }
  }
}

.mat-menu-panel {
  box-shadow: 0px 4px 4px rgba(58, 56, 120, 0.15);
  min-height: auto;

  .mat-menu-content:not(:empty) {
    padding: 0;
  }

  .mat-menu-item {
    padding: rem(8) rem(12);
    display: flex;
    align-items: center;
    @include font-style(14, 24, 700);
    color: map-get($colors, neutrals-on-bg);
    height: auto;


    .edit-action {
      text-decoration: none;
      color: map-get($colors, neutrals-on-bg);
    }

    .icon {
      font-size: rem(20);
      color: map-get($colors, neutrals-on-bg);
      margin-right: rem(8);
    }

    &.delete-btn {
      color: map-get($error, error60);

      .icon {
        color: map-get($error, error60);
      }
    }

    &:hover {
      background-color: map-get($primary, primary10);
    }
  }

  &.overlay-group-menu {
    min-height: rem(40);
    background-color: map-get($colors, neutrals-bg);
  }
}

.cdk-overlay-pane.overlay-menu {
  border-radius: rem(8);
}

.mat-paginator {
  background-color: map-get($colors, neutrals-bg);
  position: sticky;
  bottom: 0;

  .mat-paginator-container {
    padding: rem(12) 0;
  }

  .mat-paginator-page-size {
    margin-right: rem(24);
  }

  .mat-paginator-page-size-label,
  .mat-paginator-range-label {
    @include font-style(10, 12, 700);
    color: map-get($colors, neutrals-on-bg);
    margin: 0 rem(14);
  }

  .mat-paginator-range-label {
    margin-left: 0;
    margin-right: rem(32);
  }


  .mat-paginator-page-size-select,
  .mat-paginator-page-size-label {
    width: auto;
    margin: 0 rem(6);
  }

  .mat-form-field-infix {
    width: auto;
    padding: 0;
    border: 0;
  }

  .mat-form-field-appearance-legacy {
    .mat-form-field-infix {
      padding: 0;
    }

    .mat-form-field-wrapper {
      padding: 0;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
  }

  .mat-paginator-range-actions {
    .mat-paginator-navigation-first,
    .mat-paginator-navigation-previous,
    .mat-paginator-navigation-next,
    .mat-paginator-navigation-last {
      font-family: "smartbeat";
      font-size: rem(20);
      width: auto;
      height: auto;
      border-radius: rem(8);
      color: map-get($colors, neutrals-on-bg);
      padding: 0 rem(10);

      .mat-button-wrapper {
        svg {
          display: none;
        }
      }

      &:hover,
      &:focus {
        background-color: map-get($primary, primary10);
      }

      &.mat-button-disabled {
        color: map-get($grey, grey300);

        &:hover {
          background-color: transparent;
        }
      }
    }

    .mat-paginator-navigation-first {
      @extend .icon-chevrons-left;
    }

    .mat-paginator-navigation-previous {
      @extend .icon-ch-left;
    }

    .mat-paginator-navigation-next {
      @extend .icon-ch-right;
    }

    .mat-paginator-navigation-last {
      @extend .icon-chevrons-right;
    }
  }

  .mat-select {
    margin: 0;

    .mat-select-value {
      max-width: inherit;
      @include font-style(10, 16, 700);
    }

    .mat-select-trigger {
      border-width: 1px;
      padding: rem(8);
    }

    .mat-select-arrow {
      margin-right: 0;
      margin-left: rem(8);
      border: 0;
      font-family: "smartbeat";
      width: auto;
      height: auto;
      @include font-style(16, 16);
      color: map-get($colors, secondary);

      &::after {
        font-size: rem(16);
        right: 0;
        margin-top: rem(-4);
      }
    }

    &[aria-expanded="true"] {
  
      .mat-select-trigger {
        border-bottom-left-radius: rem(8);
        border-bottom-right-radius: rem(8);
        border: 1px solid transparent;
      }
    }
  
    &:focus {
      .mat-select-trigger {
        border-color: map-get($primary, primary100);
      }
  
      &[aria-expanded="true"] {
        .mat-select-trigger {
          border-color: transparent;
        }
      }
    }
  }
}

.mat-select-panel-wrap .mat-select-panel:not(.custom-mat-select) {
  box-shadow: 0px 4px 4px rgba(58, 56, 120, 0.15);

  .mat-option {
    padding: rem(8) rem(12);
    @include font-style(14, 24, 700);
    color: map-get($colors, neutrals-on-bg);
    height: auto;

    &.mat-active {
      background-color: transparent;
    }

    &.mat-selected:not(.mat-option-multiple),
    &:hover:not(.mat-option-disabled) {
      background-color: map-get($primary, primary10);

      &.mat-selected:not(.mat-option-multiple) {
        &::after {
          font-family: smartbeat;
          content: '\e90c';
          margin-right: rem(10);
          font-size: rem(20);
        }
      }
    }
  }
}

.table-skeleton-container {
  ngx-skeleton-loader .loader {
    margin-bottom: rem(8);
    border-radius: 0;
  }

  .table-skeleton-item {
    display: flex;
    flex-wrap: wrap;

    .loader {
      height: rem(52);
      border-radius: rem(12);
    }
  }

  .table-skeleton-paginator {
    .loader {
      height: rem(64);
      border-radius: rem(12);
    }
  }

  .dark {
    .loader {
      background-color: rgba(map-get($grey, grey30), 0.9);
    }
  }
}

.add-user-table {
  .table > :not(caption) > * > * {
    padding: 0;
  }
}