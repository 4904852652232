@import "src/assets/styles/var";
.custom-toast-alert{
    width: rem(800);
    min-height: rem(100);
    height: auto;
    display: flex;
    align-items: center;
    color: map-get($colors, neutrals-on-bg);
    position: relative;
    gap: rem(8);
    border-radius: rem(8);
    @include font-style(18, 20, 700);
    &-success{
        border: solid 2px map-get($success, success60 );
        background-color: map-get($success, success05 );
    }
    &-error{
        border: solid 2px map-get($error,error60);
        background-color: map-get($error, error05 );
    }
    &-warning{
        border: solid 2px map-get($warning,warning60);
        background-color: map-get($warning, warning05 );
    }
    &-info{
        border: solid 2px map-get($info,info60);
        background-color: map-get($info, info05);
    }
 

}

.icon-toast-alert-info{
    width: rem(44);
    padding-left: rem(18);
    margin-right: rem(10);  
    &-success{
      color:map-get($success, success60 );
    }
    &-error{
       color:map-get($error,error60); 
    }
    &-warning{
        color:map-get($warning,warning60);
    }
    &-info{
        color:map-get($info, info60)
    }
}

.icon-close{
    font-size: rem(24);
    cursor: pointer;
    position: absolute;
    right: rem(18);
}
.messages-container{
    display: flex;
    flex-direction: column;
}
.margin-sub{
    margin-bottom: rem(24);
    margin-top: rem(24);
    .sub-message{
        @include font-style(14, 18,400);
        padding: rem(0);
        margin-left: rem(57);
    }
}