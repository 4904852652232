@import "src/assets/styles/var";

.switch {
    cursor: pointer;
    width: rem(38);
    height: rem(22);
    border-radius: rem(40);
    background-color: map-get($colors, neutrals-disabled);
    position: relative;
    transition: 0.2s;
    .ball_switch {
      height: rem(22);
      width: rem(22);
      border-radius: rem(50);
      background-color: white;
      position: absolute;
      cursor: pointer;
      transition: 0.2s;
    }
  }
  .switch_actived{
    @extend .switch;
    background-color: map-get($primary , primary80 );
    .ball_switch{
      right:0;
    }
}