@import "src/assets/styles/var";

.container-chip {
    display: inline-flex;
    gap: rem(10);
    padding: rem(3) rem(12);
    border-radius: rem(100);
    align-items: center;
    background-color: map-get($primary , primary30 );

    &-label {
        @include font-style(14, 18, 700);
        color: map-get($primary , primary200 );
    }
    &-icon {
        cursor: pointer;
        font-size: rem(15);
        color: map-get($colors , neutrals-on-bg );
    }
    .icon-close-chip {
        position: inherit;
    }
}