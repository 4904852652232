@import "src/assets/styles/var";

.icon-checkbox-custom-select {
  color: white;
  z-index: 10;
}
.hover {
  &:hover {
    .icon-checkbox-custom-select {
      color: map-get($primary, primary10);
    }
  }
}

.custom-select {
  .label {
    @include font-style(14, 18, 500);
  }
  .container-square {
    margin-right: rem(12);
    color: map-get($colors, neutrals-on-bg);
  }
}

.container-custom-select-checkbox {
  max-height: 38vh;
  width: 100%;
  margin-top: rem(12);
  margin-bottom: rem(12);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: rem(4);
  }

  .menu-flex-custom-select {
    @include font-style(14, 16, 700);
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: rem(16);
    font-size: rem(16);
    padding: rem(12) rem(16);
    padding-left: rem(8);
    color: map-get($colors, neutrals-on-bg);
    &:hover {
      cursor: pointer;
      background-color: map-get($primary, primary10);
    }
    &:disabled {
      user-select: none;
      background-color: grey;
    }
    [aria-disabled="true"] {
      background-color: red !important;
    }
  }
  .placeholder {
    @include font-style(16, 16, 300);
    color: map-get($colors, neutrals-on-bg);
    opacity: 1;
  }
}
