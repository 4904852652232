@import "src/assets/styles/var";


.form-label {
  min-height: rem(19);
}

.container-checkbox {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: rem(46);
}


.checkbox {
  align-items: center;
  display: flex;


  &-label {
    @include font-style(16, 24, 400);
    display: flex;
    align-items: center;
    padding-left: 0.75rem;
    padding-bottom: rem(4);
    color: map-get($colors, neutrals-on-bg);
  }


  .container-checkbox-circle {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: rem(50);
    width: rem(20);
    height: rem(20);
    background-color: transparent;
    border: 1px solid blue;

    .checked {
      width: rem(10);
      height: rem(10);
    }
    .unchecked {
      width: rem(0);
      height: rem(0);
    }
    .circle {
      transition: all linear 0.2s;
      border-radius: rem(50);

      background-color: blue;
    }
  }
}
