@import "src/assets/styles/var";

.card {
  height: 100%;
  border-radius: rem(12);
  text-decoration: none;
  position: relative;
  border: 2px solid transparent;
  padding: rem(36) rem(20) rem(52);
  transition: all 0.2s ease;

  .card-body {
    padding: 0;
    padding-right: rem(64);

    .card-title {
      @include font-style(20, 24, 700);
      color: map-get($colors, neutrals-on-bg);
      margin-bottom: rem(16);
    }

    .card-text {
      @include font-style(12, 16, 400);
      color: map-get($colors, neutrals-on-bg);
      margin-bottom: 0;
    }

    .card-img {
      width: auto;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &:hover {
    border-color: map-get($grey, grey50);
  }
}