@import "src/assets/styles/var";

.container__table {
  height: 100%;
  background-color: white;
  border-radius: rem(12);
}

.icon-arrow {
  width: 10px;
  height: 10px;
  fill: red;
}

.th {
  padding: rem(10) rem(20);
  @include font-style(12, 16, 800);
}

.tr {
  height: rem(60);
  border-bottom: 1px solid map-get($grey, grey60);
  padding: rem(10);
  box-sizing: border-box;
  background-color: inherit;
  transition: background-color .4s;
  &:hover {
    background-color: map-get($grey, grey20);
  }

  .td {
    padding: rem(5) rem(20);
    color: #3a3878;
    @include font-style(14, 18, 400);

    &:first-child {
      @include font-style(14, 18, 700);
    }
  }
}

.table__thead {
  z-index: 10;
  position: sticky;
  top: 0;
}
