
.insight {
  .insights-header-container {
    position: sticky;
    top: rem(66);
    z-index: 1;
    background-color: map-get($colors, neutrals-bg);
    padding: rem(16) 0;
    
    .filtering-container {
      margin-top: rem(16);
      
      .form-group {
        margin-bottom: 0;
      }
    }
  }

  .insights-header {

    .page-title {
      margin-top: 0;
    }
  }

  .title-overview {
    @include font-style(20, 24, 700);
    color: map-get($colors, neutrals-on-bg);
    margin-bottom: rem(16);

    &.extra-margin {
      margin-bottom: rem(32);
    }
  }
  
  .overview-wrapper {
    margin: rem(24) 0;
  }
  
  .card {
  
    &.chart-card {
      padding: rem(20) 0 0 0;

      &.question-chart {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &.question-chart-card {
      display: block;
    }

    &.distribution-card {
      padding: rem(20) rem(20) rem(40);
    }

    .card-body {
      padding: 0;
  
      .number {
        margin: 0 rem(8);
      }

      .partecipation-rate {
        @include font-style(32, 38, 700);
        color: map-get($colors, neutrals-on-bg);
        padding-bottom: rem(10);
      }

      .distribution-item {
        display: flex;
        align-items: center;
        margin-bottom: rem(4);

        .emoji {
          @include font-style(24, 24);
          display: inline-block;
          margin-right: rem(4);
        }

        .value {
          @include font-style(12, 16, 400);
          color: map-get($colors, neutrals-on-bg);
          margin-bottom: 0;
          margin-right: rem(4);
        }

        .bar {
          height: rem(12);
          border-radius: rem(100);

          &.low {
            background-color: map-get($primary, primary60);
          }

          &.med {
            background-color: map-get($grey, grey80);
          }

          &.high {
            background-color: map-get($warning, warning50);
          }
        }
      }
    }

    .card-link {
      text-decoration: none;
      color: inherit;
      margin: 0 rem(8);

      &:first-child {
        margin-left: 0;
      }
    }
  }
  
  .score-container {
    width: rem(88);
    height: rem(88);
    padding: rem(8);
    background-color: map-get($primary, primary20);
    border-radius: rem(12);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    .title,
    .description {
      @include font-style(28, 32, 700);
      color: map-get($colors, neutrals-on-bg);

      &.dashboard-title {
        @include font-style(20, 24, 700);
        margin: 0;
      }
    }

    .description {
      @include font-style(14, 16, 700);
      margin-bottom: rem(4);
    }

    &.gets-better {
      background-color: map-get($warning, warning05);
    }
  }

  .deviation-container {
    padding: 0 rem(20);
    margin-bottom: rem(40);
  }
  
  .deviation {
    display: flex;
    align-items: center;
    @include font-style(10, 12, 700);
    color: map-get($colors, neutrals-on-bg);
    display: flex;
    justify-content: center;

    &.trend {
      justify-content: flex-start;
    }
  }

  .icon-trending-up,
  .icon-trending-down {
    margin-right: rem(4);
    @include font-style(14, 14);
    color: map-get($primary, primary100);
  }

  .icon-trending-down {
    color: map-get($warning, warning50);
  }
  
  .overview {
  
    .overview-card-container {
      margin-bottom: rem(20);

      .trend-container {
        display: flex;
        align-items: flex-start;

        .user-info-tooltip {
          padding: 0;
          display: inline-flex;
          margin-left: rem(8);
          color: map-get($colors, neutrals-on-bg);
        }
      }
  
      &.overview-extra {
        margin-bottom: 0;
      }
  
      .card:not(a) {
        border: 0;
      }
  
      .card-letterhead {
        @include font-style(14, 16, 700);
        color: map-get($colors, neutrals-on-bg);
        margin: rem(8) 0;

        &.no-deviation {
          margin-bottom: rem(22);
        }

        &.trend-title {
          @include font-style(16, 19, 700);
          margin-top: 0;
        }

        &.rate {
          margin-bottom: rem(12);
        }
      }
  
      .overview-card {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: rem(20) rem(20) rem(24);
  
        .global-result {
          margin-left: rem(16);
    
          .global-result-title {
            @include font-style(20, 24, 700);
            color: map-get($colors, neutrals-on-bg);
            margin-bottom: rem(8);
          }
    
          .date {
            @include font-style(12, 18, 700);
            color: map-get($grey, grey400);
          }
    
          .results {
            @include font-style(14, 18, 400);
            color: map-get($colors, neutrals-on-bg);
            margin-bottom: 0;
          }

          .results-info {
            display: flex;
            align-items: center;

            .btn-download-data {
              @include font-style(14, 18, 700);
              color: map-get($colors, neutrals-on-bg);
              padding: 0;
              border: 0;
              transition: all .2s ease-in-out;
              display: inline-flex;
              align-items: center;

              .icon-file {
                margin-right: rem(4);
              }

              &:hover {
                color: map-get($primary, primary100);
              }
            }
          }
        }
  
        .card-icon {
          @include font-style(48, 48);
          color: map-get($primary, primary200);
        }
      }

      &.chart-card-container {
        height: 100%;
        margin-bottom: 0;

        .chart-container {
          height: 100%;
        }
      }

      &.question-chart {
        height: calc(100% - 44px);
      }
    }

    .distribution-container {
      margin-top: rem(24);
      margin-bottom: 0;

      .distribution-title {
        @include font-style(20, 24, 700);
        color: map-get($colors, neutrals-on-bg);
        margin-bottom: 0;
      }
    }
  }
  
  .user-evaluation-key {
    display: flex;
    margin-top: rem(16);
    margin-bottom: rem(24);
    @include font-style(10, 12, 700);
  
    .dot-container {
      display: flex;
      margin-right: rem(24);
  
      .dot {
        width: rem(12);
        height: rem(12);
        border-radius: 100%;
        margin-right: rem(8);
      
        &.warning {
          background-color: map-get($warning, warning50);
        }

        &.light-warning {
          background-color: map-get($warning, warning20);
        }
      
        &.neutral {
          background-color: map-get($grey, grey60);
        }
      
        &.light-primary {
          background-color: map-get($primary, primary50);
        }

        &.primary {
          background-color: map-get($primary, primary100);
        }
      }
  
      .dot-description {
        margin-bottom: 0;
        color: map-get($colors, neutrals-on-bg);
      }
    }
  }
  
  .category-card {
    padding: rem(20) rem(32);
    flex-direction: row;
    align-items: center;
    margin-bottom: rem(24);
    cursor: pointer;
  
    .card-body {
      .card-title {
        margin-bottom: rem(8);
      }
    }

    .results {
      margin-bottom: 0;
      margin-top: rem(8);
      @include font-style(14, 20, 700);
      color: map-get($colors, neutrals-on-bg);
    }
  
    .category-info {
      display: flex;
      align-items: flex-start;
    }

    .comment-categories {
      display: flex;
      align-items: center;

      .comment-date {
        @include font-style(12, 16, 400);
        color: map-get($colors, neutrals-on-bg);
        margin-bottom: rem(8);
        margin-right: rem(12);
      }
    }

    .comment-text {
      @include font-style(20, 24, 700);
      color: map-get($colors, neutrals-on-bg);
      margin-bottom: rem(8);
    }

    .comment-extra-info {
      @include font-style(14, 18, 400);
      color: map-get($colors, neutrals-on-bg);
      margin-bottom: rem(8);

      .card-link {
        @include font-style(14, 20, 700);
        text-decoration: underline;
      }
    }

    &.disabled {
      cursor: default;
      pointer-events: none;

      :hover {
        border-color: none;
      }
    }
  }
  
  .category-icon {
    display: inline-flex;
    padding: rem(4);
    // TODO rimuovere quando le categorie saranno allineate
    background-color: rgba(255, 51, 120, 0.2);
    border-radius: rem(4);
    // TODO rimuovere quando le categorie saranno allineate
    color: map-get($colors, fuchsia);
    margin-right: rem(8);
  
    .icon {
      @include font-style(16, 16);
    }
  }
  
  .users-evaluation-container {
    display: flex;
    align-items: center;
    
    .users-evaluation {
      position: relative;
      height: rem(12);
  
      &.warning-bar {
        background-color: map-get($warning, warning50);
        border-radius: rem(12) 0 0 rem(12);
      }
  
      &.neutral-bar {
        background-color: map-get($grey, grey80);
      }
      
      &.primary-bar {
        background-color: map-get($primary, primary60);
        border-radius: 0 rem(12) rem(12) 0;
      }

      &.round-start {
        border-top-left-radius: rem(12);
        border-bottom-left-radius: rem(12);
      }

      &.round-end {
        border-top-right-radius: rem(12);
        border-bottom-right-radius: rem(12);
      }
  
      &::before {
        content: attr(data-value);
        @include font-style(12, 16, 400);
        color: map-get($colors, neutrals-on-bg);
        position: absolute;
        top: rem(20);
        text-align: center;
        width: 100%;
        white-space: nowrap;
      }
    }
  }
  
  .category-score {
    background-color: transparent;
    text-align: center;
  
    .title {
      margin: 0;
      @include font-style(32, 38, 700);
      color: map-get($colors, neutrals-on-bg);
    }
  }

  .comments-overview-subtitle {
    @include font-style(16, 24, 400);
    color: map-get($colors, neutrals-on-bg);
    margin-bottom: rem(24);
  }

  .chart-content {
    display: block;
    width: 100%;
    height: calc(100% - 78px);
  }
}

