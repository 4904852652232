@import "src/assets/styles/var";

.container-square {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: rem(46);
}

.radio {
  align-items: center;
  display: flex;

  &-label {
    @include truncate();
    @include font-style(16, 24, 400);
    padding-left: 0.75rem;
    padding-bottom: rem(4);
    color: map-get($colors, neutrals-on-bg);
  }

  .container-square {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    position: relative;
    width: rem(20);
    height: rem(20);
    min-width: rem(20);
    min-height: rem(20);
    background-color: transparent;
    border: 1px solid map-get($primary, primary200 );
    border-radius: rem(2.5);

    .icon-square-checked {
      color: map-get($colors, neutrals-on-bg-quaternary );
      z-index: 10;
    }

    .checked {
     opacity: 1;
    }
    .unchecked {
    opacity: 0;
    }
    .square {
      width: rem(20);
      height: rem(20);
      transition: all linear 0.2s;
      border-radius: rem(2);
      background-color: map-get($primary, primary200 );
      position: absolute;
    }
  }
}
