@import "src/assets/styles/var";

.image-drag-item {
  .obscure-layer {
    transition: background linear 0.2s;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: rem(8);
    background: rgba(0, 0, 0, 0);
  }
  border-radius: rem(8);
  position: relative;
  z-index: 100;
  height: rem(205);
  position: relative;
  transition: all linear 0.4s;

  &:hover {
    .obscure-layer {
      background: rgba(0, 0, 0, 0.5);
    }
    .button-image-hover {
      display: flex;
    }
    .title-image-hover{
      display: flex;
    }
    .button-image {
      transition: none;
      display: none;
    }
  }
  .image-uploaded {
    transition: filter linear 0.3s;
    width: 100%;
    border-radius: rem(8);
    object-fit: cover;
    height: 100%;
  }
 
  .button-image-hover {
    width: rem(44);
    height: rem(44);
    border-radius: rem(50);
    background-color: map-get($primary, primary10);
    position: absolute;
    right: rem(12);
    bottom: rem(12);
    color: black;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: none;
  }
  .title-image-hover{
    @include font-style(20, 24, 700);
    color: white;
    display: none;
    position: absolute;
    left: rem(12);
    top: rem(12)
  }
  .button-image {
    @extend .button-image-hover;
    display: flex;
  }

  @media (max-width: 1200px) {
    max-height: rem(190);
  }

  .icon-close-image {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    position: absolute;
    font-size: rem(10);
    width: rem(12);
    height: rem(12);
    cursor: pointer;
    top: rem(10);
    left: rem(10);
  }
  .tag-planimetry {
    position: absolute;
    top: rem(10);
    right: rem(10);
  }
}

.image-drag-item-view {
  @extend .image-drag-item;
  &:hover {
    background: none;
    .image-uploaded {
      opacity: inherit;
    }
    .button-image-hover {
      display: none;
    }
    .title-image-hover{
      display: none;
    }
    .button-image {
      display: flex;
      cursor: initial;
    }
  }
}

.image-drag {
  height: 100%;
  width: auto;
  &-loading {
    opacity: 0.7;
  }
}

.selected-questions-container,
.all-questions-container {
  width: 100%;
  height: calc(100vh - 16px - 66px - 50px - 24px);
  overflow: auto;
}

.selected-questions-container {
  padding-bottom: rem(24);
}

.selected-questions-list {
  overflow: auto;
  margin-bottom: rem(24);
  margin-top: rem(16);
  min-height: calc(100% - 58px - 16px - 53px - 24px);
}

.all-questions-container {
  padding: rem(24);
  padding-bottom: rem(48);
  border-top-left-radius: rem(12);
  border-top-right-radius: rem(12);
  background-color: map-get($colors, neutrals-on-primary);

  .form-group {
    margin-top: rem(16);
    position: relative;

    .form-control {
      background-color: map-get($colors, neutrals-bg);
      padding: rem(8) 0 rem(8) rem(28);

      &::placeholder {
        color: map-get($grey, grey300);
        @include font-style(14, 18, 400);
      }
    }

    .icon-search {
      position: absolute;
      left: rem(8);
      top: 50%;
      transform: translateY(-50%);
      color: map-get($grey, grey300);
      @include font-style(16, 16);
    }
  }

  .empty-all-questions {
    @include font-style(16, 19, 700);
    color: map-get($colors, neutrals-on-bg);
    text-align: center;
  }
}

.question-box {
  border-radius: rem(12);
  padding: rem(12);
  background-color: map-get($colors, neutrals-bg);
  margin-top: rem(16);
  border: 1px solid transparent;
  transition: all 0.2s ease;

  &:hover {
    border-color: map-get($grey, grey50);
    cursor: pointer;
  }

  .question-title {
    @include font-style(16, 19, 700);
    color: map-get($colors, neutrals-on-bg);
    margin-bottom: rem(12);
  }

  .question-type {
    display: flex;
    align-items: center;

    &.all-question-type {
      margin-bottom: rem(12);
    }

    .type-name {
      @include font-style(12, 16, 700);
      color: map-get($colors, neutrals-on-bg);
    }

    .icon {
      @include font-style(20, 20);
      margin-right: rem(8);
      color: map-get($colors, neutrals-on-bg);
    }
  }

  .templates-question {
    @include font-style(12, 16, 400);
    color: map-get($colors, neutrals-on-bg);
    margin-bottom: 0;

    .template {
      font-weight: 700;
    }
  }
}

.question-categories-wrapper .question-category-container:last-child {
  margin-right: 0;
}

.question-category-container {
  padding: rem(4) rem(8);
  border-radius: rem(4);
  display: inline-block;
  // TODO rimuovere quando le categorie saranno allineate
  background-color: rgba(map-get($orange, 100), 0.1);
  margin-bottom: rem(8);
  margin-right: rem(8);

  .question-category-content {
    display: flex;
    align-items: center;

    .category-name {
      @include font-style(10, 12, 700);
      color: map-get($colors, neutrals-on-bg);
      white-space: nowrap;
    }

    .icon {
      @include font-style(14, 14);
      margin-right: rem(4);
    }
  }
}

.selected-question-box {
  padding: rem(12);
  border-radius: rem(12);
  background-color: map-get($colors, neutrals-on-primary);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  margin-top: rem(16);
  cursor: pointer;
  transition: all 0.2s ease;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    border-color: map-get($grey, grey50);
    cursor: pointer;

    .delete-question-btn {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s, 300ms;
    }
  }

  .question-box-content {
    display: flex;
    align-items: flex-start;
    width: 100%;

    .question-box {
      background-color: transparent;
      padding: 0 rem(16);
      margin-top: 0;
      border: 0;
      width: 100%;
    }
  }

  .icon {
    @include font-style(16, 16);
    color: map-get($grey, grey300);
  }

  .delete-question-btn {
    padding: 0;
    border: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, 0.3s linear;

    .icon-delete {
      @include font-style(20, 20);
    }
  }
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drop-area {
  height: 50vh;
  border: 2px dotted map-get($primary, primary100);
  background-color: map-get($primary, primary10);
  border-radius: rem(12);
  text-align: center;
  position: relative;

  &-text {
    @include font-style(20, 24, 700);
    color: map-get($primary, primary70);
  }

  .upload-image-area {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor: pointer;
  }

  .container-images {
    display: flex;
    flex-wrap: wrap;
    padding: rem(24);
    z-index: 20;

    .image-item {
      z-index: 100;
      height: rem(200);
      margin: rem(12);
      position: relative;
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }

      .icon-close-image {
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 50%;
        position: absolute;
        font-size: rem(10);
        width: rem(12);
        height: rem(12);
        cursor: pointer;
        top: rem(10);
        left: rem(10);
      }
    }
  }

  &.active {
    background-color: map-get($primary, primary30);
  }

  .drop-area-placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }

  .placeholder-label {
    @include font-style(16, 20, 700);
    color: map-get($primary, primary100);
    margin-top: rem(10);
  }

  .icon-clipboard {
    @include font-style(24, 24);
    color: map-get($primary, primary100);
  }
}

.question-box.cdk-drag-preview,
.selected-question-box.cdk-drag-preview {
  background-color: map-get($primary, primary05);
  box-shadow: 0px 4px 4px rgba(58, 56, 120, 0.15);

  .form-group {
    margin-bottom: 0;
    margin-top: rem(8);
  }

  .mat-select-trigger,
  .form-control {
    background-color: map-get($primary, primary05);
  }
}

.question-box .cdk-drag-placeholder {
  opacity: 0;
}

.selected-questions-container .cdk-drag-placeholder {
  opacity: 1;
  background: map-get($primary, primary30);
  height: 2px;
  margin: rem(16) rem(32);
  padding: 0;

  .question-category-container,
  .question-title,
  .question-type,
  .delete-question-btn,
  .icon-drag2,
  .mat-select,
  .form-group,
  .categories-tooltip,
  .templates-question {
    display: none;
  }
}

/* Animate an item that has been dropped. */
.selected-question-box.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
  width: 100%;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.zone-drop-area {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 50vh;
}

.container-sortable-images {
  max-height: 100%;
  overflow-y: auto;
}

.container-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .icon-upload {
    font-size: rem(120);
    color: red;
  }

  .text {
    @include font-style(16, 24, 700);
    margin-bottom: rem(5);
    color: map-get($colors, neutrals-on-bg);
  }
}

.height-240 {
  height: rem(240);
}

.modal-advice-delete {
  position: fixed;
  width: rem(536);
  height: rem(342);
  z-index: 10;
  top: 33%;
  left: 33%;
  -webkit-animation: moveInDeleteModal 0.25s linear;
  -moz-animation: moveInDeleteModal 0.25s  linear;
  -o-animation: moveInDeleteModal 0.25s linear;
  animation: moveInDeleteModal 0.25s  linear; 
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  &-content{
    z-index: 1000;
    background-color: white;
    width:rem(550);
    height:rem(350);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: rem(8);
    // box-shadow: rem(4) rem(4) rem(8) rem(4) map-get($colors, neutrals-on-bg-tertiary );
   
   
    .row-buttons-confirm{
      display: flex;
      justify-content: space-between;
      width: 75%;
      margin-top: rem(48)

    }
    
    .text-delete-confirm{
      @include font-style(28, 38, 700);
      padding-top: rem(48);
      
    }

  }
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  display: none;
  .modal-content {
    -webkit-animation: moveIn 0.25s;
    -moz-animation: moveIn 0.25s;
    -o-animation: moveIn 0.25s;
    animation: moveIn 0.25s;
    width: rem(1400);
    min-height: rem(650);
    height: auto;
    background-color: map-get($colors, neutrals-bg);
    position: relative;
 
    .modal-head {
      padding: rem(24) rem(48);
      margin-bottom: rem(12);
      width: 100%;
      height: rem(48);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .modal-title {
        color: map-get($colors, neutrals-on-bg);
        @include font-style(20, 24, 700);
      }
    }
    .modal-main-content {
      margin-top: rem(8);
      width: 100%;
      padding: rem(0) rem(60);
      display: flex;
      gap: rem(12);
      .image-modal-container {
        width: rem(850);
        height: rem(500);
      
        .image-modal {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: rem(8);
        }
      }
      .slide-input-container {
        width: 35%;
        padding: rem(8);
        display: flex;
        flex-direction: column;
        align-items: center;
       height: rem(475);
        .input_custom {
          margin-bottom: rem(24);
          padding: rem(10) rem(8);
          border-radius: rem(8);
          height: rem(44);
          width: rem(425);
          font-style: normal;
          font-size: rem(20);
          color: map-get($colors, neutrals-on-bg);
          border: rem(1) solid map-get($primary, primary100);
          background-color: map-get($colors, neutrals-on-primary);
          box-sizing: border-box;
          cursor: pointer;
          font-family: "Satoshi";
          font-style: normal;
          font-weight: 700;
          font-size: rem(20);
          line-height: 120%;
    
          &::placeholder {
            color: map-get($colors, neutrals-on-bg);
            font-weight: 400;
            font-family: "Satoshi";
            font-style: normal;
            color: map-get($colors, neutrals-on-bg-tertiary);
            @include font-style(20, 24, 700);
          }
    
          &:focus {
            box-shadow: none;
            padding: rem(4) rem(8);
          }
          &:hover {
            box-shadow: none;
          }
        }
        .input_custom_desc {
          @extend .input_custom;
          padding: rem(10) rem(8);
          height: rem(120);
          color: map-get($colors, neutrals-on-bg-secondary);
          font-size: rem(16);
          font-weight: 500;
          &::placeholder {
            font-size: rem(16);
            font-weight: 500;
            //padding: rem(8) rem(6);
          }
          &:focus {
            padding: rem(10) rem(8);
          }
        }
        .switch_container {
          margin-top: rem(12);
          height: rem(22);
          width: 100%;
          display: flex;
          justify-content: space-between;
          .text_switch {
            @include font-style(12, 16, 700);
            color: map-get($colors, neutrals-on-bg);
            margin-left: rem(8);
          }
      
        }
        .d_row {
          display: flex;
          align-items: center;
        }
        .second_row {
          width: 50%;
          justify-content: space-evenly;
        }
        .svg_i {
          cursor: pointer;
          font-size: rem(18);
          color: map-get($colors, neutrals-on-bg);
        }
      }

    }
    .buttons-row {
      padding: rem(0) rem(60);
      margin-top: rem(18);
      width: 100%;
      height: rem(40);
      display: flex;
      justify-content: space-between;
      .size_button {
        height: rem(40);
        width: rem(88);
      }
    }
    .left-arrow {
      height: 40%;
      width: 3%;
      min-width: 2.2rem;
      left: rem(12);
      top: 30%;
      
      position: absolute;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .right-arrow {
      height: 40%;
      width: 3%;
      min-width: 2.2rem;
      right: rem(12);
      top: 30%;
      
      position: absolute;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.show-modal {
  display: flex;
}
.d-none{
  display: none;
}
textarea[name="description"] {
  resize: none;
}
.icon-close-custom{
  font-size: rem(24);
  cursor: pointer;

}

@media screen and (max-width: 1350px) and (min-width: 1180px) {
 .modal-container{
  .modal-content{
    .modal-main-content {
      .image-modal-container{
        width: rem(700);
      }
      .slide-input-container {
        width: 40%;
        .input_custom {
          width: rem(390);
        }
       
      }
    }
  }
 }
}
@media screen and (max-width: 1189px) and (min-width: 1023px) {
  .modal-container{
    .modal-content{
      .modal-main-content {
        .image-modal-container{
          width: rem(545);
        }
        .slide-input-container {
          width: 40%;
          .input_custom{
            width: rem(375);
          }
        }
      }
    }
   }
  
}

@keyframes moveIn {
  from { bottom: -25px; opacity: 0;}
  to   { bottom: 0; opacity: 1; }
}

@keyframes moveInDeleteModal {
  from { top: 32vh; opacity: 0;}
  to   { top: 33vh; opacity: 1; }
}

.obscure-background {
  transition: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.text-confirm-all{
 text-align: center;
}