.custom-mat-dialog,
.create-group-dialog,
.assign-manager-dialog,
.add-partecipants-dialog,
.add-user-dialog,
.add-survey-dialog,
.add-date-dialog,
.add-group-dialog,
.resource-dialog,
.add-comment-dialog {
  .modal-dialog-container {
    padding: rem(24) rem(24) rem(64);
    position: relative;

    .modal-dialog-header {
      display: flex;
      justify-content: flex-end;

      .close-btn {
        padding: 0;

        .icon-close {
          color: map-get($colors, neutrals-on-bg);
          font-size: rem(20);
        }
      }
    }

    .actions-container {
      margin-top: rem(32);
    }

    .description {
      @include font-style(16, 24, 400);
      color: map-get($colors, neutrals-on-bg);
      margin-top: rem(16);
    }

    .title {
      @include font-style(32, 38, 700);
      margin-top: rem(24);
      margin-bottom: 0;
      color: map-get($colors, neutrals-on-bg);
    }

    .message {
      @include font-style(16, 20);
    }
  }

  app-button + app-button {
    margin-left: rem(16);
  }

  .mat-dialog-container {
    overflow-x: hidden;
    border-radius: 0;
    box-shadow: none;
    padding: 0;
    border-radius: rem(8);
  }
}

.add-date-dialog .mat-dialog-container {
  box-shadow: 0px 4px 4px rgba(58, 56, 120, 0.15);
}

.cdk-overlay-pane.create-group-dialog .mat-dialog-container,
.cdk-overlay-pane.assign-manager-dialog .mat-dialog-container,
.cdk-overlay-pane.add-comment-dialog .mat-dialog-container,
.cdk-overlay-pane.resource-dialog .mat-dialog-container,
.cdk-overlay-pane.add-user-dialog .mat-dialog-container {
  background-color: map-get($colors, neutrals-bg);
}

.create-group-dialog, .assign-manager-dialog {
  .mat-dialog-container {
   .mat-dialog-title {
     margin-top: rem(24);
   }
  }
}