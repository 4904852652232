.form-group .group-chip-list .group-wrapper {
    padding-left: 0;
  }

  .input-text {
      min-height: 3.125rem !important;
  }

  .white {
      background-color: transparent;
  }