/* You can add global styles to this file, and also import other style files */

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1440px,
);
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../src//assets/styles/colors.scss";
@import "../src//assets/styles/mixin.scss";
// @import '~bootstrap/scss/bootstrap';
// @import '~swiper/scss';
// @import '~swiper/scss/navigation';
// @import '~swiper/scss/pagination';
// @import "~swiper/scss/grid";
@import "assets/styles/icon-font";
@import "./assets/styles/var";
@import "./assets/styles/table";
@import "./assets/styles/form";
@import "./assets/styles/select";
@import "./assets/styles/modal";
@import "./assets/styles/card.scss";
@import "./assets/styles/wysiwing";
@import "./assets/styles/color-picker";
@import "./assets/styles/insights";
@import "./assets/styles/full-calendar";
@import "./assets/styles/scroll-bar";
// @import './assets/styles/skeleton';
// @import './assets/styles/drag-and-drop';
// @import './assets/styles/question';

// @import "~@angular/material/theming";

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.

// $custom-typography: mat-typography-config(
//   $font-family: "Satoshi, Helvetica Neue, sans-serif",
// );

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// @include mat-core($custom-typography);

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 1;
}
.flex-3 {
  flex: 3;
}

.icon-button-table {
  width: rem(16);
  height: rem(16);
}

.container__home {
  height: 100%;
}

.white-space-nowrap {
  white-space: nowrap;
}

.container-custom {
  max-width: rem(1920);
  padding: 0 rem(24);
}

.custom-form-container {
  margin-top: rem(56);
  height: calc(100vh - 174px);
  overflow-y: auto;
}

.custom-form-container-stepper {
  margin-top: rem(56);
  height: calc(100vh - 245px);
  overflow-y: auto;
}

.custom-form-container-with-fixed-submit {
  margin-top: rem(26);
  height: calc(100vh - 310px);
  overflow-y: auto;
}

.capitalize-first-letter::first-letter {
  text-transform: capitalize;
}

html,
body {
  height: 100%;
}

body {
  background-color: map-get($colors, neutrals-bg);

  @media (max-width: 767px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
.d-none {
  display: none;
}
.bg-transparent {
  background-color: transparent;
}

.generic-img {
  display: inline;
  width: 100%;
  max-width: 60%;

  @media (min-width: 1024px) {
    max-width: 30%;
  }
}

.pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.color-red {
  color: map-get($error, error60);
}
.color-green {
  color: map-get($success, success60);
}
.color-blue {
  color: map-get($colors, neutrals-on-bg);
}

.display-none {
  display: none;
}

.user-thumbnail {
  width: rem(32);
  height: rem(32);
  border-radius: rem(72);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: map-get($primary, primary30);
  color: map-get($primary, primary300);
  margin-right: rem(8);
  @include font-style(10, 14, 700);
}

.cta-container {
  display: flex;
  justify-content: center;
}

.page-title {
  @include font-style(28, 32, 700);
  color: map-get($colors, neutrals-on-bg);
  // margin-top: rem(16);
}

.page-description {
  @include font-style(16, 24);
  color: map-get($colors, neutrals-on-bg);
  margin-bottom: 0;
}

.empty-page-title {
  @include font-style(32, 38, 700);
  color: map-get($colors, neutrals-on-bg);
  margin: rem(24) 0 rem(16);
}

.empty-page-description {
  @include font-style(14, 18);
  color: map-get($colors, neutrals-on-bg);
  margin-bottom: rem(32);
}

/********
 * LINK *
 ********/

.btn-link {
  display: inline-block;
  @include font-style(14, 20, 700);
  color: map-get($primary, primary100);
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: map-get($primary, primary100);
    cursor: pointer;
    box-shadow: none;
  }

  &.disabled {
    text-decoration: underline;
    color: map-get($grey, grey300);
  }
}

/************
 * SNACKBAR *
 ************/

.custom-mat-snack-bar-container.mat-snack-bar-container {
  min-width: 90vw;
  height: 100px;
  padding: rem(24);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: map-get($success, success05);
  border: 1px solid map-get($success, success60);

  &.warning {
    background-color: map-get($warning, warning05);
    border: 1px solid map-get($warning, warning60);
  }

  &.info {
    background-color: map-get($info, info05);
    border: 1px solid map-get($info, info60);
  }

  &.error {
    background-color: map-get($error, error05);
    border: 1px solid map-get($error, error60);
  }
}

/************
 * BUTTON *
 ************/

.btn-text {
  @include font-style(14, 20, 700);
  color: map-get($colors, neutrals-on-bg);
  border: 1px solid transparent;
  text-decoration: none;
  padding: rem(12);
  border-radius: rem(4);

  &:hover,
  &:focus {
    background-color: map-get($primary, primary10);
    color: map-get($primary, primary100);
  }

  &:focus {
    border-color: map-get($primary, primary100);
  }

  &:disabled,
  &[disabled] {
    background-color: transparent;

    &:hover {
      background-color: transparent;
      color: map-get($grey, grey300);
    }
  }

  &.medium {
    font-size: rem(16);
    padding: rem(16) rem(24);
    letter-spacing: 0.5%;
  }
}

.add-btn-fab {
  position: fixed;
  bottom: rem(86);
  right: rem(36);
  z-index: 1;
}

/************
 * ACCORDION SIDEBAR *
 ************/

.sidebar {
  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none;
  }

  .mat-expansion-panel {
    transition: none;

    .mat-expansion-panel-header {
      height: auto;

      .mat-expansion-indicator::after {
        border: 0;
        content: "\e907";
        font-family: smartbeat;
        color: currentColor;
        @include font-style(20, 20);
        transform: none;
      }

      .mat-content {
        align-items: center;
      }
    }

    .mat-expansion-panel-body {
      padding: 0;
    }
  }
}

.mat-expansion-panel
  .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]),
.mat-expansion-panel
  .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]),
.mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:hover:not([aria-disabled="true"]),
.mat-expansion-panel-header.mat-expanded:focus,
.mat-expansion-panel-header.mat-expanded:hover {
  background-color: map-get($primary, primary20);
}

/************
* TOOLTIP *
************/

.mat-tooltip.info-tooltip {
  position: relative;
  background-color: map-get($primary, primary600);
  color: map-get($colors, neutrals-on-primary);
  overflow: visible;
  @include font-style(12, 18, 700);
  color: map-get($colors, neutrals-on-primary);
  padding: rem(10);
  border-radius: rem(8);

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid;
    border-right: 6px solid;
    border-top: 10px solid;
    border-color: map-get($primary, primary600) transparent transparent
      transparent;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
  }
}

/************
* SPINNER *
************/

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: map-get($primary, primary70);
}

/************
* ADD USERS MODAL SKELETON *
************/

.users-table-skeleton-container {
  ngx-skeleton-loader .loader {
    margin-bottom: rem(8);
    border-radius: 0;
  }

  .users-table-skeleton-item {
    display: flex;
    flex-wrap: wrap;

    .loader {
      height: rem(40);
      border-radius: rem(8);
    }
  }
}

/************
 * STEPPER *
 ************/

.mat-stepper-horizontal {
  background-color: transparent;

  .mat-horizontal-stepper-header-container {
    width: 65%;
    height: rem(50);

    .mat-horizontal-stepper-header {
      height: rem(40);
      padding: 0;

      @media (min-width: 1440px) {
        padding: 0 rem(24);
      }

      &.step-completed {
        background-color: map-get($primary, primary60);
      }

      &:first-child {
        padding-left: 0;
      }

      .mat-step-icon {
        height: rem(28);
        width: rem(28);
        border: 1px solid map-get($colors, neutrals-on-bg);
        padding: rem(4);
        @include font-style(12, 16, 700);
        color: map-get($colors, neutrals-on-bg);
        background-color: transparent;

        &.mat-step-icon-state-edit,
        &.mat-step-icon-selected {
          color: map-get($colors, neutrals-on-primary);
          background-color: map-get($primary, primary100);
          border-color: map-get($primary, primary100);

          .icon-checkmark {
            font-size: rem(16);
            margin-top: rem(4);
          }

          &.mat-step-icon-state-edit .mat-step-icon-content {
            padding-top: rem(3);
          }
        }
      }

      .mat-step-label {
        @include font-style(14, 18, 700);
        color: map-get($colors, neutrals-on-bg);
      }

      .mat-step-header-ripple {
        display: none;
      }

      &.cdk-keyboard-focused,
      &.cdk-program-focused,
      &:hover:not([aria-disabled]),
      &:hover[aria-disabled="false"] {
        background-color: transparent;

        .mat-step-icon {
          background-color: map-get($primary, primary20);

          &.mat-step-icon-state-edit,
          &.mat-step-icon-selected {
            background-color: map-get($primary, primary80);
            border-color: map-get($primary, primary80);
          }
        }
      }
    }
  }

  .mat-horizontal-content-container {
    padding: 0;
    margin-top: rem(24);
  }

  .mat-stepper-horizontal-line {
    border-top-width: 0;
    margin: 0;

    @media (min-width: 1440px) {
      border-top-width: 0.5px;
      border-top-color: map-get($grey, grey100);
      margin: 0 rem(12);
    }
  }
}

/************
 * ADD USER MODAL *
 ************/

.item-info {
  padding: rem(10) rem(12);
  border-bottom: 1px solid map-get($grey, grey50);
  vertical-align: middle;
  text-align: left;
  @include font-style(14, 18, 400);
  color: map-get($colors, neutrals-on-bg);

  &.remove {
    padding: 0;
  }

  .remove-container {
    display: flex;
    justify-content: flex-end;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s ease-in-out;
  }

  .item-registry {
    display: flex;
    align-items: center;

    .user-thumbnail {
      width: rem(20);
      height: rem(20);
      @include font-style(6, 9, 900);
    }

    .item-name {
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}

.thumbnails-container {
  display: flex;
  align-items: center;
  z-index: 0;
  flex: 0 0 rem(44);

  .user-thumbnail {
    border: rem(2) solid map-get($colors, neutrals-on-primary);
    z-index: 2;

    &.second {
      z-index: 1;
      margin-left: rem(-20);
    }

    &.third {
      z-index: 0;
      margin-left: rem(-20);
    }
  }
}

.items {
  max-height: 400px;
  overflow: auto;

  .group-title {
    text-align: left;
    @include font-style(18, 21, 700);
    color: map-get($colors, neutrals-on-bg);
  }
}

.checkbox-container {
  width: rem(20);
  padding: 0;
  padding-left: rem(12);
  border-bottom: 1px solid map-get($grey, grey100);
  vertical-align: middle;

  .mat-checkbox {
    display: inline-flex;

    .mat-checkbox-inner-container {
      margin-right: 0;
    }
  }
}

.user-container {
  padding: 0;
  border: 0;

  .user-info {
    padding: rem(4) rem(12);
    border-bottom: 1px solid map-get($grey, grey100);
    vertical-align: middle;
    text-align: left;

    .user-registry-container {
      display: flex;
      align-items: center;

      .user-registry-content {
        display: flex;

        .user-thumbnail {
          margin-right: rem(8);
          width: rem(20);
          height: rem(20);
          @include font-style(6, 9);
        }

        .user-data {
          @include font-style(12, 16);
          color: map-get($colors, neutrals-on-bg);
          text-align: left;
        }
      }
    }

    .user-name-surname {
      @include font-style(14, 18, 700);
      color: map-get($colors, neutrals-on-bg);
      margin-bottom: 0;
    }

    .user-role {
      @include font-style(14, 18);
      color: map-get($colors, neutrals-on-bg);
      margin: 0;
    }

    &.groups {
      padding: 0 rem(12);
    }

    .groups-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .user-group {
        padding: rem(4) rem(8);
        @include font-style(10, 12, 700);
        border-radius: rem(4);
        background-color: map-get($primary, primary30);
        color: map-get($colors, neutrals-on-bg);
        margin: rem(4) rem(8) rem(4) 0;
      }
    }
  }
}

/************
 * TOGGLE BUTTON *
 ************/

.mat-button-toggle-group {
  flex-wrap: wrap;

  .mat-button-toggle-button {
    padding: rem(12);
  }

  .mat-button-toggle {
    margin-right: rem(16);
    margin-bottom: rem(8);
    border-radius: rem(12);
    border: 1px solid transparent;
    @include font-style(16, 19, 700);
    color: map-get($colors, neutrals-on-bg);
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    & + .mat-button-toggle {
      border-left-color: transparent;
    }

    &.mat-button-toggle-checked {
      border-left-color: map-get($primary, primary100);
      background-color: map-get($primary, primary20);
      border: 1px solid map-get($primary, primary100);

      &:hover {
        border-color: map-get($primary, primary100);
      }
    }

    &:hover {
      background-color: map-get($primary, primary20);

      .mat-button-toggle-focus-overlay {
        background-color: transparent;
        border-radius: rem(12);
        border: 1px solid transparent;
        opacity: 1 !important;
      }
    }
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0;
    display: flex;
    align-items: center;
    line-height: rem(28);

    .count-word {
      margin-left: rem(8);
      width: rem(28);
      height: rem(28);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      @include font-style(12, 16, 700);
      background-color: map-get($primary, primary100);
      color: map-get($colors, neutrals-on-primary);
      padding: rem(4);
    }
  }

  &.resources-filters-group {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    .mat-button-toggle {
      @include font-style(14, 18, 700);
      color: map-get($colors, neutrals-on-bg);
      margin-right: rem(20);
      border-radius: rem(100);
      margin-bottom: 0;
      border: 0;
      overflow: initial;

      &.mat-button-toggle-appearance-standard {
        background-color: transparent;

        .mat-button-toggle-label-content {
          line-height: rem(18);
        }
      }

      &.mat-button-toggle-checked {
        color: map-get($primary, primary200);
        background-color: map-get($primary, primary30);

        &:hover {
          background-color: map-get($primary, primary30);
        }
      }

      &:hover {
        background-color: map-get($primary, primary30);
      }
    }

    .mat-button-toggle-button {
      padding: rem(8) rem(12);
      background-color: transparent;
    }
  }
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: 0;
  cursor: pointer;
}

/************
 * MENU *
************/

.truncate {
  @include truncate();
}

.categories-menu-panel.mat-menu-panel {
  max-height: rem(160);
  min-width: rem(216);
  box-shadow: 0px 4px 4px rgba(58, 56, 120, 0.15);

  .mat-menu-item {
    display: flex;
    align-items: center;
    padding: rem(8) rem(36) rem(8) rem(8);
    @include font-style(12, 16, 700);

    &.selected {
      background-color: map-get($primary, primary10);

      &::after {
        font-family: smartbeat;
        content: "\e90c";
        position: absolute;
        right: rem(8);
        font-size: rem(20);
      }
    }

    &.all-category-item {
      padding-left: rem(36);
    }
  }
}

.card-actions-menu.mat-menu-panel {
  min-height: auto;

  .mat-menu-item {
    line-height: rem(18);
  }
}

/************
 * COMMENT CHAT *
************/

.comment {
  overflow: hidden;

  .comment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: rem(16);

    .page-title {
      margin-bottom: 0;
    }
  }

  .comment-info {
    background-color: map-get($colors, neutrals-on-primary);
    border-radius: rem(12);
    margin: rem(28) 0;
    height: calc(100% - 56px);

    &.no-data {
      padding-bottom: rem(82);
    }

    .skeleton-wrapper {
      padding: rem(20);
      overflow: hidden;
      max-height: 100%;
    }
  }
}

/************
 * CAROUSEL *
************/

.cards-slider,
.surveys-slider {
  width: 100%;
  height: 100%;
  height: rem(656);
  padding-bottom: rem(28);

  .swiper-pagination {
    bottom: 0;

    .swiper-pagination-bullet {
      background-color: map-get($grey, grey80);
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: map-get($grey, grey300);
    }
  }
}

.surveys-slider {
  height: rem(340);

  @media (min-width: 1440px) {
    height: rem(656);
  }

  .swiper-slide {
    height: 100%;
  }
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 100%;
  background-color: map-get($primary, primary20);
  color: map-get($colors, neutrals-on-bg);
  padding: rem(10);
  width: rem(44);

  &::after {
    content: "\e903";
    font-family: smartbeat;
    @include font-style(24, 24);
  }

  &.swiper-button-disabled {
    display: none;
  }
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;

  &::after {
    content: "\e902";
  }
}

.cards-slider {
  &.resize-width .swiper-wrapper {
    width: calc(100vw - 176px - 48px - 25%) !important;
  }

  .swiper-slide {
    transition: all 0.4s;
    height: calc(50% - 10px);
    width: calc(33.3333333% - 20px);

    /* &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      position: absolute;
  
      .flip-card-back {
        transform: rotateY(180deg);
      }
    }
  
    &:nth-child(1) {
      top: 45%;
      left: 55%;
      transform: translate(-45%, -55%);
  
      .flip-card-front {
        background-image: url('assets/img/card6.jpg');
      }
    }

    &:nth-child(2) {
      margin-top: 0 !important;
      top: 46%;
      left: 54%;
      transform: translate(-46%, -54%);
  
      .flip-card-front {
        background-image: url('assets/img/card5.jpg');
      }
    }
    &:nth-child(3) {
      top: 47%;
      left: 53%;
      transform: translate(-47%, -53%);
  
      .flip-card-front {
        background-image: url('assets/img/card4.jpg');
      }
    }
    &:nth-child(4) {
      margin-top: 0 !important;
      top: 48%;
      left: 52%;
      transform: translate(-48%, -52%);
  
      .flip-card-front {
        background-image: url('assets/img/card3.jpg');
      }
    }
    &:nth-child(5) {
      top: 49%;
      left: 51%;
      transform: translate(-49%, -51%);
  
      .flip-card-front {
        background-image: url('assets/img/card2.jpg');
      }
    }
    &:nth-child(6) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 0 !important;
  
      .flip-card-front {
        background-image: url('assets/img/card1.jpg');
      }
    } */
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: map-get($colors, neutrals-on-bg);
  height: calc(100vh - 230px);

  &.comments-empty {
    display: block;
    height: auto;
    text-align: center;
  }

  .empty-state-img {
    margin-top: rem(52);
  }

  .empty-state-title {
    @include font-style(32, 38, 700);
    margin-top: rem(24);
    margin-bottom: rem(16);
  }

  .empty-state-description {
    @include font-style(14, 18, 400);
  }
}

/************
*  SEARCH   *
************/

.resources-searching {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: rem(24);

  .form-group {
    margin-bottom: 0;
    flex: 0 0 40%;
    position: relative;

    .form-control {
      padding: rem(12) 0;
      padding-left: rem(40);
    }

    .icon-search {
      @include font-style(24, 24);
      position: absolute;
      left: rem(8);
      top: 50%;
      transform: translateY(-50%);
      color: #9a9ca5;
    }

    .icon-close {
      @include font-style(24, 24);
      cursor: pointer;
      position: absolute;
      right: rem(8);
      top: 50%;
      transform: translateY(-50%);
      color: #9a9ca5;
      &:hover {
        color: map-get($primary, primary300);
      }
    }
  }
  .input-searchbar-realestates-table {
    flex: 0 0 40%;
  }
  @media (max-width: 1240px) {
    .input-searchbar-realestates-table {
      flex: 0 0 75%;
    }
  }
}

/************
*  TABLES   *
************/

.title-custom {
  @include font-style(14, 16, 700);
  text-align: center;
  color: map-get($colors, neutrals-on-bg);
  margin: 0;
}

.formLabel {
  @extend .title-custom;
  font-size: 0.87rem !important;
}

hr {
  opacity: 0.1;
}

.menu-item {
  @include font-style(14, 16, 700);
  display: flex;
  align-items: center;
  width: 100%;
  font-size: rem(16);
  padding: rem(12) rem(8);
  color: map-get($colors, neutrals-on-bg);
  background-color: white;
  transition: background-color 0.4s;
  opacity: 1;

  .icon {
    margin-right: rem(16);
  }

  &:hover {
    cursor: pointer;
    background-color: map-get($primary, primary20);
  }

  &-red {
    &:hover {
      cursor: pointer;
      background-color: rgba(map-get($error, error60), 0.1);
    }
  }
  &-green {
    &:hover {
      cursor: pointer;
      background-color: rgba(map-get($success, success60), 0.1);
    }
  }
  &-blue {
    &:hover {
      cursor: pointer;
      background-color: rgba(map-get($primary, primary300), 0.1);
    }
  }
}

.menu-button {
  @include font-style(14, 18, 400);
  padding: rem(12) rem(16);
  border-radius: rem(8);
  background-color: map-get($primary, primary30);
  height: rem(50);
}

.container__table {
  height: 100%;
  height: calc(100vh - 320px);
}

.container__top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: rem(10);
}

.truncate {
  @include truncate();
}

.visibility_hidden {
  visibility: hidden;
}

.d-none {
  display: none;
}
