@import "src/assets/styles/var";

.calendar {
  background-color: #fbfdfd;
  border: none;
  &::placeholder {
    color: red;
  }
}
.container-calendar {
  padding: rem(4) rem(8);
  text-align: center;

  &::placeholder {
    @include font-style(16, 24, 500);
    font-family: "Satoshi";
    margin-left: rem(16);
    color: map-get($colors, neutrals-on-bg);
  }
}
.react-datepicker {
  border: none;
  box-shadow: rgba( map-get($colors , neutrals-on-bg ), 0.15) 0px 0.0625em 0.0625em, rgba( map-get($colors , neutrals-on-bg ), 0.15) 0px 0.125em 0.5em, rgba( map-get($colors , neutrals-on-bg ), 0.1) 0px 0px 0px 1px inset;

}
.calendar-wrapper {
  height: 100%;
}
.react-datepicker__input-container {
  height: 100%;
}

.calendar-custom-input {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: rem(8);

  display: inline-block;
  .data-label {
    @include font-style(16, 24, 700);
    color: map-get($colors, neutrals-on-bg);
  }
  .data-label-placeholder {
    @extend .data-label;
    @include font-style(16, 24, 500);
  }
}
.icon-calendar {
  position: absolute;
  top: 28%;
  left: rem(6);
  z-index: 10;
}

.container-calendar-header {
  display: flex;
  justify-content: space-between;
  padding: rem(4) rem(12);
  background-color: #fbfdfd;
  .icon-arrow {
    font-size: rem(18);
    color: map-get($colors, neutrals-on-bg);
  }
  .react-datepicker {
    // border: 1px map-get($colors , neutrals-on-bg );
    box-shadow: rgba( map-get($colors , neutrals-on-bg ), 0.15) 0px 0.0625em 0.0625em, rgba( map-get($colors , neutrals-on-bg ), 0.15) 0px 0.125em 0.5em, rgba( map-get($colors , neutrals-on-bg ), 0.1) 0px 0px 0px 1px inset;
    }
      .month-label {
    @include font-style(14, 24, 700);
    padding: rem(0) rem(2);
    font-family: "Satoshi";
    color: map-get($colors, neutrals-on-bg);
  }
}

.container-calendar {
  border: none;

}
.calendar-week-day {
  background-color: #fbfdfd;
  @include font-style(14, 18, 500);
  font-family: "Satoshi";
  color: map-get($colors, neutrals-on-bg);
}
.react-datepicker__day-names {
  background-color: #fbfdfd;
}
.react-datepicker__header {
  border: none;
  background-color: #fbfdfd;
}
.react-datepicker__triangle {
  display: none;
}
.calendar-custom-day {
  padding: rem(2) rem(0);
  font-family: "Satoshi";
  background-color: transparent;
  border-radius: rem(100);

  color: map-get($colors, neutrals-on-bg);
  @include font-style(12, 24, 700);
  &:hover {
    background-color: #bcbdc5;
    border-radius: rem(100);
  }
}
.calendar-custom-day-today {
  @extend .calendar-custom-day;
  background-color: #e4e6f0;
}
.calendar-custom-day-selected {
  @extend .calendar-custom-day;
  background-color: #d4d5dc;
  border-radius: rem(100);
  &:hover {
    background-color: #bcbdc5;
    border-radius: rem(100);
  }
}

.react-datepicker__month-text {
  @include font-style(12, 24, 500);
  padding: rem(0) rem(2);
  font-family: "Satoshi";
  color: map-get($colors, neutrals-on-bg);
}

.react-datepicker__month-text--keyboard-selected {
  background-color: transparent;
}
.react-datepicker__day--selected:hover {
  background-color: #d4d5dc;
}
.react-datepicker__day--selected {
  background-color: #bcbdc5 !important;
}
.react-datepicker__month--selected {
  background-color: #d4d5dc;
  &:hover {
    background-color: #bcbdc5 !important;
  }
}
