@import "src/assets/styles/var";

.background-condition-select {
    background-color: #D9DFFB;
    display: flex;
    flex-direction: column;
    width: 32%;
    padding: rem(10);
    border-radius: 5px;

    .title-condition-select {
        margin-bottom: rem(5);
        color:map-get($colors, neutrals-on-bg );
        @include font-style(12, 18, 700)
    }
}