@import "src/assets/styles/var";

.container__pagination {
  background-color: white;
  max-width: 18rem;
  border-radius: 1rem;
  margin-top: 0.2rem;
  display: flex;
  justify-content: space-between;
}

.page__button {
  padding: 0 !important;
}

.pages-label {
  @include font-style(10, 12, 700);
  padding: rem(0) rem(24);
  color: map-get($colors, neutrals-on-bg);
}

.icon-page {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(18);
}
