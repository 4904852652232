@import "src/assets/styles/var";
.drawer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    top: 0; 
    background-color: map-get($colors, neutrals-bg);
    transition: left 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
    animation: enterAnimation 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  
  @keyframes enterAnimation {
    from {
      left: 100vw;
    }
    to {
      left: 0vw;
    }
  }
  
.is-open {
    left: 0;
}
.is-closed {
    left: 100vw;
}
