@import "src/assets/styles/var";

.group-color {
  width: rem(20);
  height: rem(20);
  border-radius: 50%;
  flex: 0 0 auto;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 0;
  display: block;

  &:focus-visible {
    outline: none;
    border-color: map-get($primary, primary100);
  }
}

.color-picker.open {
  border-radius: rem(12);
  box-shadow: 8px 8px 32px rgba(25, 39, 114, 0.15);
  overflow: hidden;
  border: 0;
  padding: rem(20);

  .hue-alpha {
    padding: 0 rem(8);
    margin-bottom: rem(16);
  }

  .hsla-text .box input,
  .rgba-text .box input,
  .hex-text .box input {
    padding: rem(10) rem(8);
    @include font-style(16, 24, 700);
    color: map-get($colors, neutrals-on-bg);
    font-family: "Satoshi";
    height: auto;
    border: 1px solid transparent;
    border-radius: rem(8);

    &:focus,
    &:focus-visible {
      outline: none;
      border-color: map-get($primary, primary100);
    }
  }

  .hsla-text,
  .rgba-text {
    padding: 0;
    padding-bottom: rem(16);

    .box {
      padding: 0;
      padding-right: rem(20);

      input {
        text-align: center;
        padding: rem(10) rem(4);
      }

      &:last-child {
        margin-top: rem(12);

        div {
          display: flex;
          justify-content: center;
          @include font-style(12, 16, 700);
          color: map-get($colors, neutrals-on-bg);
        }
      }
    }
  }

  .hex-text {
    padding: 0;

    .box {
      padding: 0;
      width: rem(208);
  
      div {
        @include font-style(12, 16, 700);
        color: map-get($colors, neutrals-on-bg);
        margin-bottom: rem(16);
        margin-top: rem(12);
      }
  
      input {
        text-align: left;
      }
    }
  }

  .arrow-bottom {
    border: 0;
  }

  .type-policy {
    background-image: none;
    top: rem(248);
    right: rem(16);

    .type-policy-arrow {

      &:after {
        font-family: smartbeat;
        position: absolute;
        right: rem(5);
        color: map-get($colors, neutrals-on-bg);
      }

      &:first-child {
        &:after {
          content: '\e90a';
        }
      }

      &:last-child {
        &:after {
          content: '\e907';
        }
      }
    }
  }

  .preset-area {
    padding: 0;

    hr {
      color: map-get($grey, grey50);
    }

    .cp-preset-colors-class {

      .preset-color {

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .preset-label {
      padding: rem(16) rem(0);
      @include font-style(12, 16, 700);
      color: map-get($colors, neutrals-on-bg);
    }
    
    .preset-color {
      border-color: transparent;
      border-radius: 100%;
      width: rem(24);
      height: rem(24);
      margin: 0 rem(8);
    }
  }
}