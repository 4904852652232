
@font-face {
  font-family: 'smartbeat';
  src:  url('/assets/fonts/icons/smartbeat.eot?l06k9b');
  src:  url('/assets/fonts/icons/smartbeat.eot?l06k9b#iefix') format('embedded-opentype'),
    url('/assets/fonts/icons/smartbeat.ttf?l06k9b') format('truetype'),
    url('/assets/fonts/icons/smartbeat.woff?l06k9b') format('woff'),
    url('/assets/fonts/icons/smartbeat.svg?l06k9b#smartbeat') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'smartbeat' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activity:before {
  content: "\e900";
}
.icon-add:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-bg-color:before {
  content: "\e904";
}
.icon-bold:before {
  content: "\e905";
}
.icon-book-open:before {
  content: "\e906";
}
.icon-ch-down:before {
  content: "\e907";
}
.icon-ch-left:before {
  content: "\e908";
}
.icon-ch-right:before {
  content: "\e909";
}
.icon-ch-up:before {
  content: "\e90a";
}
.icon-chart:before {
  content: "\e90b";
}
.icon-checkmark:before {
  content: "\e90c";
}
.icon-chevrons-left:before {
  content: "\e90d";
}
.icon-chevrons-right:before {
  content: "\e90e";
}
.icon-clear-search:before {
  content: "\e90f";
}
.icon-clipboard:before {
  content: "\e910";
}
.icon-clock:before {
  content: "\e911";
}
.icon-close:before {
  content: "\e912";
}
.icon-code:before {
  content: "\e913";
}
.icon-coffee:before {
  content: "\e914";
}
.icon-comment:before {
  content: "\e915";
}
.icon-delete:before {
  content: "\e916";
}
.icon-drag2:before {
  content: "\e917";
}
.icon-edit:before {
  content: "\e918";
}
.icon-error:before {
  content: "\e919";
}
.icon-eye:before {
  content: "\e91a";
}
.icon-file:before {
  content: "\e91b";
}
.icon-filter:before {
  content: "\e91c";
}
.icon-font-color:before {
  content: "\e91d";
}
.icon-grid:before {
  content: "\e91e";
}
.icon-home:before {
  content: "\e91f";
}
.icon-image:before {
  content: "\e920";
}
.icon-info:before {
  content: "\e921";
}
.icon-italic:before {
  content: "\e922";
}
.icon-link:before {
  content: "\e923";
}
.icon-more:before {
  content: "\e924";
}
.icon-notifications:before {
  content: "\e925";
}
.icon-ordered-list:before {
  content: "\e926";
}
.icon-pause:before {
  content: "\e927";
}
.icon-people:before {
  content: "\e928";
}
.icon-person:before {
  content: "\e929";
}
.icon-pie-chart:before {
  content: "\e92a";
}
.icon-planner:before {
  content: "\e92b";
}
.icon-play:before {
  content: "\e92c";
}
.icon-redo:before {
  content: "\e92d";
}
.icon-search:before {
  content: "\e92e";
}
.icon-settings:before {
  content: "\e92f";
}
.icon-speaker:before {
  content: "\e930";
}
.icon-star:before {
  content: "\e931";
}
.icon-sun:before {
  content: "\e932";
}
.icon-txt-align-c:before {
  content: "\e933";
}
.icon-txt-align-l:before {
  content: "\e934";
}
.icon-txt-align-r:before {
  content: "\e935";
}
.icon-underline:before {
  content: "\e936";
}
.icon-undo:before {
  content: "\e937";
}
.icon-unordered-list:before {
  content: "\e938";
}
.icon-upload:before {
  content: "\e939";
}
.icon-warning:before {
  content: "\e93a";
}
.icon-dash:before {
  content: "\e93b";
}
.icon-arrow-up:before {
  content: "\e93c";
}
.icon-arrow-down:before {
  content: "\e93d";
}
.icon-communication:before {
  content: "\e93e";
}
.icon-filters:before {
  content: "\e93f";
}
.icon-happiness:before {
  content: "\e940";
}
.icon-leadership:before {
  content: "\e941";
}
.icon-processes:before {
  content: "\e942";
}
.icon-smart-working:before {
  content: "\e943";
}
.icon-team-members:before {
  content: "\e944";
}
.icon-trending-down:before {
  content: "\e945";
}
.icon-trending-up:before {
  content: "\e946";
}
.icon-work-environment:before {
  content: "\e947";
}
.icon-work-life-balance:before {
  content: "\e948";
}
.icon-heart-filled:before {
  content: "\e949";
}
.icon-heart:before {
  content: "\e94a";
}
.icon-help:before {
  content: "\e94b";
}


