@import "src/assets/styles/var";
/* width */
::-webkit-scrollbar {
  width: rem(8);
  height: rem(8);
}

/* Track */
::-webkit-scrollbar-track {
  background: map-get($primary, primary10);
  border-radius: rem(10);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map-get($primary, primary50);
  border-radius: rem(10);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: map-get($primary, primary100);
}

.chakra-menu__menu-list {
  &::-webkit-scrollbar {
    width: rem(4);
  }
}
