@import "src/assets/styles/var";

.stepper_line {
  width: 100%;
  height: rem(52);
  margin-bottom: rem(16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .button-back {
    gap: rem(20);
    @include font-style(14,20, 700);
    .icon{
      font-size: rem(20);
    }
  }
  .button-next {
    gap: rem(20);
    @include font-style(14,20, 700)
  }
  .icon{
    font-size: rem(20);
  }
}
.center_column {
  display: flex;
  flex-direction: column;
  text-align: center;
}



.progress_custom {
  width: 100%;
  height: rem(6);
  background-color: #c6cef9;
  border-radius: rem(20);
  position: relative;
  .progress_actived {
    height: rem(6);
    position: absolute;
    top: 0;
    background-color: transparent;
    background-color: #3b54d4;
    border-radius: rem(20);
    transition: 0.3s linear;
  }
}

.text_stage {
  padding :rem(3) 0;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: rem(20);
  line-height: 120%;
  color: map-get($colors, neutrals-on-bg);
}
.text_step{
  @extend .text_stage;
  font-size: rem(16);
  
}
