@import "src/assets/styles/var";

.form-group {
  margin-bottom: rem(32);

  &.disabled {
    .form-control-label {
      color: map-get($grey, grey300);
    }
  }

  .form-control-label {
    @include font-style(12, 18, 700);
    @include truncate();
    color: map-get($colors, neutrals-on-bg);
    margin-bottom: rem(8);
    min-height: rem(18);
    display: block;
  }
  .title-red {
    color: map-get($error, error70);
  }

  .form-control {
    padding: rem(9) rem(8);
    border-radius: rem(8);
    height: auto;
    min-height: auto;
    color: map-get($colors, neutrals-on-bg);

    @include font-style(16, 24, 700);
    border: 1px solid transparent;
    background-color: map-get($colors, neutrals-on-primary);
    box-sizing: border-box;

    &::placeholder {
      color: map-get($colors, neutrals-on-bg);
      font-weight: 400;
    }

    &:focus,
    &:hover {
      box-shadow: none;
      border-color: map-get($primary, primary100);
    }

    &:disabled,
    &[readonly] {
      color: map-get($grey, grey300);
      background-color: map-get($colors, neutrals-on-primary);
      border-color: transparent;
      cursor: not-allowed;
    }

    &.input-variant {
      border: 0;
      border-bottom: 1px solid transparent;
      padding: 0;
      padding-bottom: rem(8);
      @include font-style(16, 19, 700);
      box-shadow: none;
      border-radius: 0;

      &.show-input {
        border-bottom-color: map-get($primary, primary100);
        background-color: map-get($colors, neutrals-on-primary);
      }
    }

    &.question-input {
      &[readonly] {
        cursor: text;
      }
    }
  }

  .form-control-title {
    @extend .form-control;
    background-color: transparent;
    padding: rem(9) rem(0);
    &:focus {
      border-color: map-get($primary, primary100);
      &:hover {
        border-color: map-get($primary, primary100);
      }
    }
    &:hover {
      border-color: transparent;
    }
  }
  .form-control-title-appointment {
    @extend .form-control;
    @include font-style(24, 29, 700);
    background-color: transparent;
    padding: rem(9) rem(0);
    &:focus {
      border-color: map-get($primary, primary100);
      &:hover {
        border-color: map-get($primary, primary100);
      }
    }
    &:hover {
      border-color: transparent;
    }
  }

  &.is-invalid {
    .form-control-label,
    .form-control {
      color: map-get($error, error70);
    }

    .form-control,
    .mat-select .mat-select-trigger {
      border-color: map-get($error, error70);

      &::placeholder,
      .mat-select-placeholder {
        color: map-get($error, error70);
      }
    }

    .form-control-show {
      @extend .form-control;
      background-color: red;
    }

    .invalid-feedback {
      display: block;
      color: map-get($error, error70);
      @include font-style(12, 18, 700);
      margin-top: rem(8);
    }
  }

  &.form-datepicker {
    .datepicker-container {
      position: relative;

      .icon-container {
        position: absolute;
        top: 50%;
        left: rem(1);
        transform: translateY(-50%);
      }

      .clear-icon-container {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .icon-planner,
      .icon-clear-search {
        color: map-get($colors, neutrals-on-bg);
      }

      .form-control {
        padding-left: rem(48);
      }
    }

    .mat-datepicker-toggle,
    .mat-datepicker-content .mat-calendar-next-button,
    .mat-datepicker-content .mat-calendar-previous-button {
      color: map-get($colors, secondary);
    }

    .mat-icon-button {
      font-size: rem(24);
      display: flex;
      align-items: center;
      justify-content: center;

      .mat-button-wrapper {
        display: inline-block;
        line-height: rem(24);
      }
    }
  }

  /************
  * AUTOCOMPLETE *
  ************/

  .group-chip-list {
    font-family: "Satoshi", "Helvetica Neue", sans-serif;
    padding: 0;
    width: 100%;

    .group-wrapper {
      border: 1px solid transparent;
      border-radius: rem(8);
      overflow: hidden;
      padding-left: rem(40);

      &.input-wrapper-focused {
        border-color: map-get($primary, primary100);
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-flex {
        padding: 0;
        background-color: map-get($colors, neutrals-on-primary);
        border-radius: rem(8);

        .mat-form-field-infix {
          border: 0;
          padding: 0;
        }
      }

      .mat-form-field-underline {
        display: none;
      }
    }

    .mat-chip-list-wrapper {
      flex-wrap: nowrap;
      overflow: auto;
      margin: 0;
      padding: 0 rem(8);
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }

      .mat-chip-input {
        border: 0;
        @include font-style(16, 24, 700);
        color: map-get($colors, neutrals-on-bg);
        padding: rem(9) rem(8);
        border-radius: rem(8);
        margin: 0 rem(-8);

        &:focus,
        &:focus-visible {
          outline: none;
        }

        &::placeholder {
          color: map-get($colors, neutrals-on-bg);
          font-weight: 400;
        }
      }
    }
  }
}

.mat-chip.mat-standard-chip {
  margin: 0;
  margin-right: rem(12);
  padding: rem(4) rem(12);
  color: map-get($primary, primary200);
  @include font-style(14, 18, 700);
  background-color: map-get($primary, primary30);
  display: flex;
  align-items: center;
  white-space: nowrap;

  .mat-chip-remove {
    display: flex;
    align-items: center;
    opacity: 1;

    .icon-close {
      font-size: rem(20);
      color: map-get($primary, primary200);
    }
  }

  &:hover {
    color: map-get($colors, neutrals-on-primary);
    background-color: map-get($primary, primary200);

    .icon-close {
      color: map-get($colors, neutrals-on-primary);
    }
  }

  &.mat-standard-chip {
    &::after {
      transition: none;
      background-color: transparent;
    }

    &:not(.mat-chip-disabled) .mat-chip-remove:hover {
      opacity: 1;
    }
  }
}

.mat-autocomplete-panel {
  border-radius: rem(8);

  &:not([class*="mat-elevation-z"]) {
    box-shadow: none;
  }

  .mat-option {
    padding: 0 rem(8);
    @include font-style(16, 24, 700);
    color: map-get($colors, neutrals-on-bg);

    &.mat-active,
    &.mat-selected:not(.mat-option-multiple),
    &:hover:not(.mat-option-disabled) {
      background-color: map-get($primary, primary10);
    }
  }
}

.cdk-overlay-pane.overlay-autocomplete {
  .mat-autocomplete-panel
    .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
    color: map-get($colors, neutrals-on-bg);
  }

  .add-user-autocomplete {
    margin-top: rem(4);
    border-radius: rem(8);

    .mat-option {
      padding: rem(8) rem(12);
      height: auto;

      .user-name-surname {
        @include font-style(14, 16, 700);
        color: map-get($colors, neutrals-on-bg);
        margin-bottom: rem(4);
      }

      .user-email {
        @include font-style(12, 16);
        color: map-get($colors, neutrals-on-bg);
      }
    }
  }
}

/************
 * DATEPICKER *
************/

.mat-calendar {
  font-family: "Satoshi", "Helvetica Neue", sans-serif;
}

.mat-calendar-header {
  .mat-calendar-arrow {
    margin-left: rem(24);
    border: 0;

    &::after {
      font-family: smartbeat;
      content: "\e907";
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      font-size: rem(24);
    }
  }

  .mat-button-wrapper {
    color: map-get($colors, neutrals-on-bg);
    @include font-style(14, 18, 400);
    display: flex;
    align-items: center;
  }

  .mat-focus-indicator {
    color: map-get($colors, neutrals-on-bg);
    box-shadow: none;
  }
}

.mat-calendar-body {
  .mat-calendar-body-cell-content {
    color: map-get($colors, neutrals-on-bg);
    @include font-style(16, 24, 700);
  }

  .mat-calendar-body-active.mat-calendar-body-cell-content {
    background-color: map-get($primary, primary200);
    color: map-get($colors, neutrals-on-primary);
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: map-get($primary, primary30);
  }

  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border: 0;
    background-color: map-get($grey, grey70);
  }

  .mat-calendar-body-disabled {
    color: map-get($grey, grey300);
  }

  .mat-calendar-body-today {
    background-color: map-get($grey, grey70);

    &.mat-calendar-body-selected {
      box-shadow: none;
    }
  }

  .mat-calendar-body-selected {
    background-color: map-get($primary, primary200);
    color: map-get($colors, neutrals-on-primary);
  }
}

.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: map-get($grey, grey70);
  color: map-get($colors, neutrals-on-bg);
}

.mat-calendar-table-header .mat-calendar-abbr {
  color: map-get($colors, neutrals-on-bg);
  @include font-style(14, 18, 400);
  text-decoration: none;
  cursor: default;
}

.mat-datepicker-popup .mat-datepicker-content {
  border-radius: rem(8);
  box-shadow: none;
}

.inline-datepicker {
  background-color: map-get($colors, neutrals-bg);
  box-shadow: 0px 4px 4px rgba(58, 56, 120, 0.15);
}

.mat-calendar-body-in-range::before {
  background: map-get($primary, primary30);
}

/************
 * CHECKBOX *
************/

.mat-checkbox {
  .mat-checkbox-layout {
    align-items: center;
    transition: all 0.2s ease-in-out;
  }

  .mat-checkbox-inner-container {
    width: rem(20);
    height: rem(20);
    margin-right: rem(12);

    .mat-checkbox-ripple {
      width: rem(36);
      height: rem(36);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: initial;
    }

    &:hover {
      .mat-ripple-element {
        opacity: 1;
        background-color: map-get($primary, primary10);
      }
    }
  }

  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: map-get($primary, primary200);
    z-index: 2;
  }

  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element,
  &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
    .mat-ripple-element {
    background-color: map-get($primary, primary10);
  }

  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    border-radius: rem(2);
  }

  .mat-checkbox-frame {
    border: 1px solid map-get($primary, primary200);
    border-radius: rem(2);
    z-index: 2;
  }

  .mat-checkbox-label {
    color: map-get($colors, neutrals-on-bg);
    @include font-style(14, 18, 400);
  }

  &.survey,
  &.group,
  &.survey .mat-checkbox-layout,
  &.group .mat-checkbox-layout,
  &.survey .mat-checkbox-layout .mat-checkbox-label,
  &.group .mat-checkbox-layout .mat-checkbox-label {
    width: 100%;
  }

  &.survey .mat-checkbox-layout,
  &.group .mat-checkbox-layout {
    padding: rem(10);
    border-bottom: 1px solid map-get($grey, grey20);

    &:hover {
      background-color: map-get($grey, grey20);
    }
  }

  &.group .mat-checkbox-layout .mat-checkbox-label,
  &.survey .mat-checkbox-layout .mat-checkbox-label {
    padding-left: rem(76);
    @include font-style(14, 18, 700);
  }

  &.survey.mat-checkbox-checked,
  &.group.mat-checkbox-checked,
  &.survey.mat-checkbox-checked .mat-checkbox-layout,
  &.group.mat-checkbox-checked .mat-checkbox-layout {
    background-color: map-get($primary, primary05);
  }
}

/************
 * RADIO *
************/

.mat-radio-outer-circle {
  border-width: 1px;
  border-color: map-get($primary, primary200);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-accent .mat-radio-outer-circle {
  border-width: 1px;
  border-color: map-get($primary, primary200);
}

.mat-radio-button.mat-accent {
  .mat-radio-inner-circle {
    background-color: map-get($primary, primary200);
  }

  .mat-radio-ripple .mat-ripple-element {
    display: none;
  }

  .mat-radio-label-content {
    padding-left: rem(12);
    color: map-get($colors, neutrals-on-bg);
    @include font-style(16, 24, 400);
  }
}

/************
 * TOGGLE *
 ************/

.toggle-container {
  height: rem(44);
  display: flex;
  align-items: center;
  margin-top: rem(30);

  .mat-slide-toggle-content {
    color: map-get($colors, neutrals-on-bg);
    @include font-style(12, 16, 700);
    font-family: "Satoshi", "Helvetica Neue", sans-serif;
    white-space: break-spaces;
  }

  .mat-slide-toggle-bar {
    height: rem(20);
    border-radius: rem(68);
    background-color: map-get($colors, neutrals-disabled);

    &:hover {
      .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
        background-color: transparent;
      }
    }
  }

  .mat-slide-toggle-thumb-container {
    top: 0;

    .mat-slide-toggle-thumb {
      box-shadow: none;
    }
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: map-get($primary, primary80);
  }

  .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #ffffff;
    border: 1px solid map-get($primary, primary80);
  }
}

.border-none {
  border: none !important;

  &:hover,
  &:focus,
  &:focus-visible {
    border: none !important;
  }
}
