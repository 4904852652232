@import "src/assets/styles/var";

.title-body-modal {
  color: map-get($colors, neutrals-on-bg);
  margin-bottom: rem(24);
  @include font-style(18, 18, 700);
}
.container-conditions-body-modal {
  display: flex;
  flex-wrap: wrap;
  gap: rem(20);

  margin-bottom: rem(40);
  .condition-body-modal {
    width: rem(220);
    height: rem(51);
  }
  .condition-body-modal-selected {
    @extend .condition-body-modal;
    background-color: map-get($primary, primary30);
  }
}

.select-group-conditions {
  width: rem(170);
  overflow: hidden;
}
