@import "src/assets/styles/var";

.drawer-body {
  background-color: #edf2f6;
}

.container-search-input {
  width: 100%;
  .search-input {
    padding: rem(9) rem(8);
    &:focus-visible {
      outline: none;
    }
  }
}

.container-menu-item {
  [aria-disabled="true"] {
    pointer-events: none !important;
    opacity: 0.4;
  }
}

.icon-add-user {
  width: rem(20);
  height: rem(20);
  margin-right: rem(4);
  cursor: pointer;
}

.container-drawer-form {
  height: calc(100vh - 25px);
}
