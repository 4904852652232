@import "src/assets/styles/var";

.container-title-demand {
    margin-bottom: rem(12);

    &-title {
        margin-bottom: rem(22);
    }
    &-input {

        width: rem(700);
        
        input {
            font-size: 1.75rem !important;
            line-height: 2rem;
            font-weight: 700;
        }
    }
}