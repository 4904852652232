@import "src/assets/styles/var";

.btn-custom {
  @include font-style(16, 20, 700);
  min-width: rem(166);
  padding: rem(16) rem(24);
  border-radius: rem(8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in;
  position: relative;

  &:disabled,
  &[disabled] {
    color: map-get($grey, grey300);
    background-color: map-get($colors, neutrals-disabled);
    // cursor: not-allowed;
    // pointer-events: all;

    &:hover {
      background-color: map-get($colors, neutrals-disabled);
    }
  }

  .element-icon {
    margin-right: rem(8);
  }

  &:focus {
    box-shadow: none;
  }

  &.full-width {
    width: 100%;
    min-width: auto;
  }

  &.position-icon-right {
    flex-direction: row-reverse;

    .element-icon {
      margin-right: 0;
      margin-left: rem(8);
    }
  }
}

.medium {
  padding: rem(12) rem(24);
}

.bt-primary {
  @extend .btn-custom;
  background-color: map-get($primary, primary100);
  border: 1px solid map-get($primary, primary100);
  color: map-get($colors, neutrals-on-primary);

  &:hover,
  &:focus {
    background-color: map-get($primary, primary80);
    border-color: map-get($primary, primary80);
    color: map-get($colors, neutrals-on-primary);
  }

  &:disabled,
  &[disabled] {
    border-color: transparent;
    color: map-get($grey, grey300);
    cursor: not-allowed;
    &:hover {
      background-color: map-get($colors, neutrals-disabled);
    }
  }

  &.outline {
    background-color: transparent;
    border: 1px solid map-get($primary, primary100);
    color: map-get($primary, primary100);

    &:hover,
    &:focus {
      background-color: map-get($primary, primary10);
      border-color: map-get($primary, primary100);
    }

    &:disabled,
    &[disabled] {
      border-color: map-get($grey, grey200);
      color: map-get($grey, grey300);

      &:hover {
        background-color: transparent;
      }
    }

    &.active {
      background-color: map-get($primary, primary20);
    }

    &.outline-small {
      padding: rem(10) rem(24);
    }
    &.outline-big {
      padding: rem(10) rem(18);
      font-size: rem(14);
    }
  }

  &.btn-text {
    background-color: transparent;
    border-color: transparent;
    color: map-get($primary, primary100);

    &:hover,
    &:focus {
      background-color: map-get($primary, primary10);
      color: map-get($primary, primary100);
    }

    &:focus {
      border-color: map-get($primary, primary100);
    }

    &:disabled,
    &[disabled] {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }

    &.btn-text-small {
      padding: rem(12);
      @include font-style(14, 20, 700);
      min-width: auto;

      &.btn-text-with-icon {
        @include font-style(14, 20, 700);
        color: map-get($colors, neutrals-on-bg);
        background-color: transparent;
        padding: rem(12);
        margin-left: rem(4);

        .icon {
          @include font-style(20, 20);
        }

        &.btn-without-text .icon {
          margin: 0;
        }

        &:hover,
        &:focus {
          color: map-get($primary, primary100);
          background-color: map-get($primary, primary10);
        }

        &:focus {
          border-color: map-get($primary, primary100);
        }
      }
    }
  }
}

.bt-secondary {
  @extend .bt-primary;
  background-color: transparent;
  border-color: map-get($error, error60);
  color: map-get($error, error60);

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: map-get($error, error50);
    color: map-get($error, error50);
  }

  &:disabled,
  &[disabled] {
    border-color: transparent;
    color: map-get($grey, grey300);
    cursor: not-allowed;
    &:hover {
      background-color: map-get($colors, neutrals-disabled);
    }
  }

}
.bt-tertiary {
  @extend .bt-primary;
  background-color: transparent;
  border-color: map-get($primary, primary100);
  color: map-get($primary, primary100);

  &:hover,
  &:focus {
    background-color: map-get($primary, primary10);
    border-color: map-get($primary, primary100);
    color: map-get($primary, primary100);
  }


  &:disabled,
  &[disabled] {
    border-color: transparent;
    color: map-get($grey, grey300);
    cursor: not-allowed;
    &:hover {
      background-color: map-get($colors, neutrals-disabled);
    }
  }

}

.clear {
  @extend .btn-custom;
  min-width: auto;
  background-color: transparent;
  border-color: transparent;
  color: map-get($colors, neutrals-on-bg);
  @include font-style(24, 28, 700);
  padding: rem(8) rem(4);
  margin: rem(-8) rem(-4);

  .icon {
    @include font-style(32, 32);
    margin-right: rem(4);
  }

  &:hover {
    background-color: transparent;
    border-color: transparent;
  }

  &:focus {
    background-color: map-get($primary, primary10);
    border-color: map-get($primary, primary100);
  }
}

.btn-circle {
  width: rem(44);
  min-width: auto;
  height: rem(44);
  font-size: rem(20);
  border-radius: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .element-icon {
    color: map-get($colors, neutrals-on-primary);
    margin-left: 0;
    margin-right: 0;
  }

  &:focus {
    background-color: map-get($primary, primary70);
  }

  &:disabled,
  &[disabled] {
    background-color: map-get($grey, grey50);

    .element-icon {
      color: map-get($grey, grey300);
    }
  }

  &.fab-outline {
    background-color: map-get($colors, neutrals-on-primary);

    .element-icon {
      color: map-get($primary, primary100);
    }
  }
}

.btn-icon {
  min-width: auto;
  @include font-style(32, 32);
  padding: rem(12);
  line-height: inherit;
  color: map-get($colors, neutrals-on-bg);
  background: none;
  border-color: transparent;
  transition: all 0.2s ease-in-out;

  .element-icon {
    margin-left: 0;
    margin-right: 0;
  }

  &:hover {
    background-color: transparent;
    border-color: transparent;

    .icon {
      color: map-get($primary, primary100);
    }
  }

  &:focus {
    background-color: map-get($primary, primary10);
    border-color: transparent;

    .icon {
      color: map-get($primary, primary100);
    }
  }

  &:disabled {
    background-color: transparent;
    border-color: transparent;

    &:hover {
      background-color: transparent;
    }

    .icon {
      color: map-get($grey, grey300);
    }
  }

  &.accent {
    color: map-get($colors, neutrals-on-bg);

    &:hover {
      background: transparent;
      border-color: transparent;

      .icon {
        color: map-get($colors, d-accent);
      }
    }
  }
}

.btn-icon-medium {
  @extend .btn-icon;
  padding: rem(10);
  @include font-style(24, 24);
}

.btn-icon-small {
  @extend .btn-icon;
  padding: rem(10);
  @include font-style(20, 20);

  &:hover {
    background-color: map-get($primary, primary10);
  }
}

.no-hover {
  &:hover,
  :focus {
    background-color: transparent;
  }
}
.no-border {
  border: none;
  &:hover,
  :focus {
    border: none;
  }
}

