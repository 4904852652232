@font-face {
  font-family: 'Satoshi';
  src: url('/assets/fonts/satoshi-regular/Satoshi-Regular.woff2') format('woff2'),
       url('/assets/fonts/satoshi-regular/Satoshi-Regular.woff') format('woff'),
       url('/assets/fonts/satoshi-regular/Satoshi-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('/assets/fonts/satoshi-medium/Satoshi-Medium.woff2') format('woff2'),
       url('/assets/fonts/satoshi-medium/Satoshi-Medium.woff') format('woff'),
       url('/assets/fonts/satoshi-medium/Satoshi-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('/assets/fonts/satoshi-bold/Satoshi-Bold.woff2') format('woff2'),
       url('/assets/fonts/satoshi-bold/Satoshi-Bold.woff') format('woff'),
       url('/assets/fonts/satoshi-bold/Satoshi-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}