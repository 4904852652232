@import "src/assets/styles/var";
.container-matches-realestate-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: rem(12);
  .container-title {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .margin-top {
    margin-top: rem(64);
  }
}
