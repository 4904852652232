@import "src/assets/styles/var";

.container-conditions {
  padding: rem(0) rem(12);
  margin-bottom: rem(48);

  .subtitle-conditions-demand {
    margin-top: rem(40);
    margin-bottom: rem(20);
    color: map-get($colors, neutrals-on-bg);
    @include font-style(16, 18, 700);
  }

  .background {
    margin-top: rem(10);
    background-color: #f3f5fd;
    border: 1px dashed #7a8ef1;
    border-radius: rem(5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: rem(150);
    padding: rem(24) rem(48);
  }

  .position-conditions {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: rem(20);
    flex-direction: row;
    margin-bottom: rem(46);
  }
}
.container-footer-conditions {
  display: flex;
  margin-top: rem(32);
  justify-content: center;
  gap: rem(10);
}
.title {
  margin-bottom: rem(12);
}

.hide-scrollbar {
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: rem(10);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: rem(10);
  }
}

.modal-body-conditions {
  height: rem(500);
  overflow-y: scroll;

  .filters-line {
    margin-top: rem(30);
    display: flex;
    justify-content: space-between;

    .select-filter {
      display: flex;
      width: rem(300);
      overflow: hidden;
      margin-right: rem(20);
    }
  }
}

.chips-line {
  margin: rem(20) 0;
  display: flex;

  .single-chip {
    margin-left: rem(10);
  }
}
