@import "src/assets/styles/var";


.mat-select-trigger {
  background-color: map-get($colors, neutrals-on-primary);
  padding: rem(9) rem(8);
  border-radius: rem(8);
  font-family: "Satoshi", "Helvetica Neue", sans-serif;
  border: 1px solid transparent;

  &:focus {
    border-color: map-get($primary, primary100);
  }

  .mat-select-placeholder,
  .mat-select-value {
    @include font-style(16, 24);
    color: map-get($colors, neutrals-on-bg);
  }

  .mat-select-value-text {
    font-weight: 700;
  }

  .mat-select-arrow {
    border: 0;
    border: 0;
    width: auto;
    height: auto;
    display: flex;

    &::after {
      font-family: smartbeat;
      content: '\e907';
      @include font-style(24, 24);
      color: map-get($colors, neutrals-on-bg);
    }
  }
}

.custom-mat-select.mat-select-panel {
  box-shadow: 0px 4px 4px rgba(58, 56, 120, 0.15);

  .mat-option {
    @include font-style(16, 24, 700);
    height: auto;
    padding: rem(8);
    color: map-get($colors, neutrals-on-bg);

    &.mat-active {
      background-color: transparent;
    }

    &.mat-selected:not(.mat-option-multiple),
    &:hover:not(.mat-option-disabled) {
      background-color: map-get($primary, primary10);

      &.mat-selected:not(.mat-option-multiple) {
        &::after {
          font-family: smartbeat;
          content: '\e90c';
          margin-right: rem(10);
          font-size: rem(20);
        }
      }
    }
  }
}

.mat-select {
  &[aria-expanded="true"] {

    .mat-select-trigger {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid transparent;

      .mat-select-arrow {
        &::after {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }
    }
  }

  &:focus {
    .mat-select-trigger {
      border-color: map-get($primary, primary100);
    }

    &[aria-expanded="true"] {
      .mat-select-trigger {
        border-color: transparent;
      }
    }
  }

  &.variant-bg {

    &.insight {
      .mat-select-trigger {
        background-color: map-get($primary, primary10);
        padding: rem(10) rem(8);
        
        .mat-select-value-text {
          @include font-style(14, 18, 400);
        }
      }
    }

    &.resources-type {
      width: auto;
  
      .mat-select-trigger {
        padding: rem(12) rem(16);
      }
  
      .mat-select-value {
        max-width: 100%;
      }

      .mat-select-arrow {
        margin-left: rem(8);
      }
    }

    .mat-select-trigger {
      background-color: map-get($colors, neutrals-bg);
    }
  }

  &.question-select {
    width: auto;
    max-width: rem(250);

    .mat-select-trigger {
      padding: rem(8);
      padding-left: 0;
      border: 0;

      .mat-select-value {
        max-width: inherit;
        vertical-align: middle;

        .icon {
          @include font-style(20, 20);
        }
      }
      
      .mat-select-placeholder,
      .mat-select-value-text,
      .mat-select-value  {
        @include font-style(12, 20, 700);
      }

      .mat-select-value-text mat-select-trigger {
        display: flex;
        align-items: center;

        .icon {
          @include font-style(20, 20);
          margin-right: rem(8);
          color: map-get($colors, neutrals-on-bg);;
        }
      }

    }

    .mat-select-arrow {
      margin: 0;
      margin-left: rem(24);
  
      &::after {
        @include font-style(20, 20);
      }
    }
  }
}

.variant-panel {
  
  .mat-option-text {
    display: inline-flex;
    align-items: center;
    @include font-style(14, 20, 700);

    .icon {
      @include font-style(20, 20);
      margin-right: rem(8);
    }
  }

  &.insight-select .mat-option-text {
    line-height: rem(18);
  }
}