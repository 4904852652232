@import "src/assets/styles/var";
.loader_page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 100;
  overflow-y: hidden;
  align-items: center;
}

.loader_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.in-progress-title {
  @include font-style(32, 38, 700);
  color: map-get($colors, neutrals-on-bg);
  margin: rem(32) 0;
}
