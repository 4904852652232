@import "src/assets/styles/var";

.compact-realestate-title {
  @include font-style(24, 28.8, 700);
  color: map-get($colors, neutrals-on-bg);
}
.compact-realestate-text {
  @include font-style(16, 24, 500);
  color: map-get($grey, grey400);
  white-space: pre-line;
}

.container-compact-realestate {
  height: calc(100vh - 100px);
  overflow-y: auto;
  .container-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .title {
    @include font-style(20, 38.4, 700);
  }
  .street {
    @include font-style(18, 28, 400);
  }

  .container-body {
    margin-top: rem(49);
    display: flex;
    gap: rem(10);
    &-left {
      flex: 9;
    }
    &-right {
      flex: 3;
    }
  }
}

.map {
  width: 100%;
  height: rem(600);
  border: 4px white solid;
  border-radius: rem(8);
}
