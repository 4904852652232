@import "src/assets/styles/var";

.menu-flex {
  @include font-style(14, 16, 700);
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: rem(16);
  gap: rem(16);
  padding: rem(12) rem(16);
  padding-left: rem(8);
  color: map-get($colors, neutrals-on-bg);
  &:hover {
    cursor: pointer;
    background-color: map-get($primary, primary10);
  }
  &:disabled {
    user-select: none;
    background-color: grey;
  }
  [aria-disabled="true"] {
    background-color: red !important;
  }
}

.menu-button {
  background-color: white !important;
}

.menu-button-value {
  @include font-style(16, 16, 700);
}
.menu-button-default {
  @include font-style(16, 16, 300);
}

.container-text-default {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-custom-select-checkbox {
  .chakra-menu__menu-button {
    width: 100%;
  }
}
