@import "src/assets/styles/var";
.card_container {
  position: relative;
  width: 100%;
  background: white;
  border-radius: rem(16);
  display: flex;
  flex-direction: row;
  padding: 2%;
  height: 100%;
  max-height: rem(295);
  gap: 1.5%;

  .card-rif {
    @include font-style(14, 16.8, 700);
    color: map-get($colors, neutrals-on-bg);
    margin-left: rem(16);
  }

  .portal-logo {
    width: rem(24);
    height: rem(24);
    border-radius: 50%;
  }

  .pointer-portal-logo {
    width: rem(9);
    height: rem(9);
    border-radius: 50%;
    position: absolute;
    bottom: rem(-1);
    right: rem(-1);
  }
  .opacity {
    opacity: 0.6;
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  }

  .portal-logo-grey {
    background-color: grey;
  }
  .portal-logo-green {
    background-color: rgb(2, 152, 2);
    border: #1a265e solid 1px;
  }

  .portal-logo-red {
    background-color: red;
    border: #1a265e solid 1px;
  }
  .portal-logo-yellow {
    background-color: rgb(255 209 0);
    border: #1a265e solid 1px;
  }
  .card-consultant {
    @include font-style(14, 16.8, 700);
    color: map-get($colors, neutrals-on-bg);

    &-icon {
      font-size: rem(16);
      color: map-get($colors, neutrals-on-bg);
    }
  }

  .container_image {
    width: 22%;
    position: relative;
    min-width: rem(200);
    height: rem(200);
    .card_image {
      width: 100%;
      height: 100%;
      border-radius: rem(8);
      object-fit: cover;
    }
  }

  .card_content_main {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .card_data {
      font-size: rem(14);
      line-height: rem(24);
      color: map-get($grey, grey400);
    }
    .card_title {
      color: map-get($colors, neutrals-on-bg);
      font-size: rem(24);
      font-weight: 700;
      line-height: 120%;
      text-transform: capitalize;
    }
    .card-subtitle {
      font-weight: 700;
      margin-top: 8px;
    }
    .text_icons {
      display: flex;
      margin-top: 12px;

      .price {
        white-space: nowrap;
        font-weight: 500;
        font-size: rem(18);
        line-height: rem(28);
        color: map-get($colors, neutrals-on-bg);
      }
      .chips {
        height: 100%;
        padding-left: rem(12);
        display: flex;
        align-items: center;

        .chip {
          height: rem(25);
          width: rem(80);
          border-radius: rem(4);
          display: flex;
          align-items: center;
          background-color: map-get($primary, primary15);
          justify-content: space-evenly;
          .text_chip {
            font-size: rem(10);
            font-weight: 700;
            line-height: 120%;
            color: map-get($colors, neutrals-on-bg);
          }
        }
      }
    }
    .card_description {
      margin-top: 16px;
      font-size: rem(16);
      line-height: rem(24);
      color: map-get($grey, grey400);
      text-overflow: ellipsis;
      overflow: hidden;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }
  .card_three_dots {
    width: 3%;
    height: 100%;
    margin-right: rem(-15);
  }
}

.green_type {
  position: absolute;
  top: rem(12);
  left: rem(12);
}

.title-modal-portal {
  padding-top: rem(38) !important;
  padding-bottom: rem(24) !important;
}

.container-checkbox-circle {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: rem(50);
  width: rem(18);
  height: rem(18);
  background-color: transparent;
  border: 1px solid blue;
  margin-top: 4px;

  .checked {
    width: rem(10);
    height: rem(10);
  }
  .unchecked {
    width: rem(0);
    height: rem(0);
  }
  .circle {
    transition: all linear 0.2s;
    border-radius: rem(50);
    background-color: blue;
  }
}
.sentAt {
  margin-left: rem(16);
  display: flex;
  gap: rem(4);
}
.suspensive-date {
  margin-left: rem(32);
  display: flex;
  gap: rem(4);
}
.text-expiration {
  color: map-get($colors, neutrals-on-bg);
  font-size: rem(18);
}

.grey-border {
  border: 1px solid grey;
  border-radius: rem(7);
  padding: rem(1);
  opacity: 0.6;
}
.external-icons {
  width: rem(30);
  height: rem(30);
  border-radius: rem(7);
  padding: rem(4);
}

.deactivated {
  opacity: 0.6;
  border: rem(1) solid rgb(187, 185, 185);
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1);
}
.activated {
  opacity: 1;
  background-color: rgba(243, 245, 253, 1);
}
.bg-green {
  background-color: #a2eb88;
}
.bg-red {
  background-color: #ffa785;
}
.bg-yellow {
  background-color: #fff185;
}

.pointer-external-logo {
  width: rem(9);
  height: rem(9);
  border-radius: 50%;
  position: absolute;
  bottom: rem(-1);
  right: rem(-1);
}
.border-rounded {
  border-radius: rem(7);
}
.radio-style {
  transform: scale(1.3);
  accent-color: #0d132f;
}
.input-percentage {
  width: 97%;
}
.position-percentage {
  position: relative;
  right: rem(30);
}
