@import "src/assets/styles/var";


.logo {
  padding-left: rem(16);
}

.icon-sidebar {
  margin: rem(4);
  width: rem(24);
  height: rem(24);
}
.icon-headbar {
  width: rem(24);
  height: rem(24);
  color: map-get($colors, neutrals-on-bg);
}

.disabled-link {
  pointer-events: none;
}

.logo-text {
  @include font-style(18, 16, 800);
}

.title-custom {
  @include font-style(14, 16, 700);
  text-align: center;
  color: map-get($colors, neutrals-on-bg);
  margin: 0;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: rem(44);
  border-radius: 4.5rem;
  height: rem(44);
  background-color: map-get($primary, primary30);
  color: map-get($primary, primary300);
  @include font-style(14, 20, 700);

  &[aria-expanded="true"] {
    border-color: map-get($primary, primary100);
    box-shadow: none;
  }

  &:hover,
  &:focus {
    background-color: rgba(map-get($primary, primary300), 0.2);
    color: map-get($primary, primary300);
  }

  &:focus {
    border-color: map-get($primary, primary200);
    box-shadow: none;
  }
}
.section-left {
  .left {
    padding: rem(8) rem(8);
    display: flex;
    align-items: center;
    .text {
      @extend .title-custom;
      color: map-get($primary, primary200);
    }
    .link {
      color: map-get($colors, neutrals-on-bg);
      cursor: pointer;
    }
    .icon {
      @extend .icon;
      font-size: 1.25rem;
    }
  }
}

.section-right {
  display: flex;
  align-items: center;
  .bell-icons {
    display: none;
    margin-right: 22px;
  }
  .language-menu {
  @include font-style(14, 16, 500);
  color: map-get($colors, neutrals-on-bg);
  }
  .language-arrow {
    width: rem(15);
    height: rem(15);
  }

  .icon-notifications {
    @include font-style(16, 18, 400);
  }
}

.nav-link-custom {
  @include font-style(14, 16, 700);
  padding: rem(8) rem(8);
  margin: rem(12) rem(20);
  display: flex;
  cursor: pointer;
  align-items: center;
  color: map-get($colors, neutrals-on-bg);
  border-radius: rem(8);
  transition: background-color 0.4s;

  .icon {
    @include font-style(20, 20);
    margin-right: rem(4);
    padding: rem(4);
  }

  &.active {
    color: map-get($primary, primary200);
    background-color: map-get($primary, primary10);
  }

  &:hover {
    background-color: map-get($primary, primary20) !important;
  }

  &.submenu {
    padding: rem(12);
    padding-left: rem(42);
  }

  &.active-submenu {
    color: map-get($primary, primary100);
  }
}

.navbar {
  padding: rem(16) rem(24) rem(4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: map-get($colors, neutrals-bg);
  z-index: 2;

  .actions-container {
    display: flex;
    align-items: center;

    .help-btn {
      margin-right: rem(12);
      padding: rem(10);
      text-decoration: none;
      display: inline-flex;
      transition: all 0.2s ease-in;
      color: map-get($colors, neutrals-on-bg);

      &:hover {
        color: map-get($primary, primary100);
      }

      .icon {
        @include font-style(24, 24);
        color: currentColor;
      }
    }

    .logout-label {
      color: map-get($colors, neutrals-on-bg);
    }
  }

  .actions-navbar {
    padding: 0;
    border-radius: 100%;
    border: 1px solid transparent;

    .user-thumbnail {
      margin: 0;
      width: rem(44);
      height: rem(44);
      @include font-style(14, 20, 900);
    }

    &[aria-expanded="true"] {
      border-color: map-get($primary, primary100);
      box-shadow: none;
    }

    &:hover,
    &:focus {
      background-color: rgba(map-get($primary, primary300), 0.2);
      color: map-get($primary, primary300);
    }

    &:focus {
      border-color: map-get($primary, primary100);
      box-shadow: none;
    }
  }
}

.icon-navbar {
  @include font-style(24, 24);
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  transition: all 0.2s ease-in;
  color: map-get($colors, neutrals-on-bg);

  &:hover {
    color: map-get($primary, primary100);
  }
}

.pointer{
  cursor: pointer;
}