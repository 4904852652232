@import "src/assets/styles/var";
.container-general-information {
  background-color: map-get($primary, primary20);
  border: 1px solid map-get($primary, primary100);
  padding: rem(24);
  border-radius: rem(12);
  .title {
    margin-bottom: rem(0);
    font-size: rem(20);
    line-height: 120%;
    font-weight: 700;
    color: map-get($colors, neutrals-on-bg);
  }
  .sub-title {
    margin-bottom: rem(0);
    font-size: rem(16);
    line-height: 120%;
    font-weight: 700;
    color: map-get($colors, neutrals-on-bg);
  }
  .label {
    margin-bottom: rem(0);
    margin-top: rem(12);
    font-size: rem(14);
    line-height: 120%;
    font-weight: 500;
    color: map-get($colors, neutrals-on-bg);
  }
  .cursor-pointer {
    cursor: pointer;
  }
}
