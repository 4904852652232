@import "src/assets/styles/var";

.container-modal-change-consultant{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(16);

  &-label {
    @include font-style(16, 24, 500);
    color: map-get($colors, neutrals-on-bg);

  }
}

.title-modal {
  @include font-style(32, 38, 700);
  color: map-get($colors, neutrals-on-bg);
  text-align: center;
  padding-bottom: rem(16);
margin-top: rem(40);
}

.container-modal-buttons {
  display: flex;
  justify-content: center;
  gap: rem(16);
  margin-top: rem(32);
}

.container-consultants {
  padding: rem(0);
  background-color: #fff;
  border-radius: rem(8);
  max-height: rem(400);
  overflow-y: auto;


  .container-select {
    cursor: pointer;
    &:hover {
      background-color: map-get($primary, primary10);
    }

    &-text {
      padding: rem(11) rem(12);
      @include font-style(16, 18, 700);
  
    }
  
    .email {
      @include font-style(12, 16, 400);
      padding-left: rem(12) ;
      padding-bottom: rem(11) ;
  
    }
    
  }

}

.consultant-selected {
  padding: rem(0);
  background-color: #fff;
  border-radius: rem(8);
  height: rem(40);
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-text {
    @include font-style(16, 24, 700);
  }
}



