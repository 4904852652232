@import "src/assets/styles/var";

.snack-chip {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: rem(5);
  padding: rem(4) rem(12);
}

.snack-chip-border-radius-md {
  border-radius: rem(100);
}
.snack-chip-border-radius-sm {
  border-radius: rem(4);
}



.info-chip {
  @include font-style(14, 18, 700);
  background-color: map-get($primary, primary30);
  color: map-get($primary, primary200);
  .icon-chip {
    font-size: rem(16);
    color: map-get($primary, primary200);
  }
}
.warning-chip {
  @include font-style(14, 18, 700);
  background-color: map-get($warning, warning10);
  color: map-get($warning, warning60);
  .icon-chip {
    font-size: rem(16);
    color: map-get($warning, warning60);
  }
}
.error-chip {
  @include font-style(14, 18, 700);
  background-color: map-get($error, error10);
  color: map-get($error, error70);
  .icon-chip {
    font-size: rem(16);
    color: map-get($error, error70);
  }
}
.success-chip {
  @include font-style(14, 18, 700);
  background-color: map-get($success, success10);
  color: map-get($success, success90);
  .icon-chip {
    font-size: rem(16);
    color: map-get($success, success90);
  }
}
.info-secondary-chip {
  @include font-style(14, 18, 700);
  background-color: map-get($fuchsia, fuchsia10);
  color: map-get($fuchsia, fuchsia900);
  .icon-chip {
    font-size: rem(16);
    color: map-get($fuchsia, fuchsia900);
  }
}

.neutral-text {
  color: map-get($colors, neutrals-on-bg )
}