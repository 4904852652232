@import "src/assets/styles/var";

.container__createCustomer {
  overflow-y: scroll;
  height: 85vh;
  width: 100%;
  padding: 0 1rem;
  .formLabel {
    margin: 10px 0;
    display: inline-block;
    font-weight: 700;
  }
}
.full {
  height: 100vh;
}
.container-overflow {
  overflow-y: auto;
}

.container-create-customer {
  height: calc(100vh - 110px);
}

.subtitle {
  text-align: center;
  width: 100%;
  padding-bottom: 1.3rem;
  font-style: italic;
}

.button-toast-add-new-customer{
  @include font-style(14,18,500);
  margin-left: rem(54);
  text-decoration: underline;
}