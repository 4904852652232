@import "src/assets/styles/var";
.specific-information-title {
  @include font-style(24, 28.8, 700);
  color: map-get($colors, neutrals-on-bg);
}
.specific-information-text {
  @include font-style(16, 24, 500);
  color: map-get($grey, grey400);
}

.specific-information-table {
  width: 100%;

  .tr {
    border: none;
    padding: rem(0);
    &:hover {
        background-color: transparent;
    }
  }

  .th {
    padding: rem(0);
    width: 33%;
    @include font-style(18, 21.6, 700);
    color: map-get($colors, neutrals-on-bg);
  }
  .td {
    @extend .specific-information-text;
    padding: rem(0);
  }
}
