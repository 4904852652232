@import "src/assets/styles/var";

.container-grid-images {
  display: flex;
  gap: rem(10);
  max-height: rem(600);
  .container-left {
    flex: 2;
    .image {
      width: 100%;
      height: 100%;
      border-radius: rem(10);
      object-fit: cover;
    }
  }
  .container-right {
    flex: 1;
    flex-direction: column;
    display: flex;
    gap: rem(10);

    .h-49 {
      height: 49%;
    }
    .image {
      width: 100%;
      height: 49%;
      border-radius: rem(10);
      object-fit: cover;
    }

    .image-bottom {
      width: 100%;
      height: 100%;
      border-radius: rem(10);
      object-fit: cover;
    }
    .container-show-gallery {
      position: absolute;
      right: rem(10);
      bottom: rem(10);
    }
  }
}
.container-tabs {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  padding: rem(8) rem(42.5);
  margin-left: rem(4);
  margin-right: rem(4);
  width: rem(168);
  border-radius: rem(100);
  transition: background-color .2s linear;
  .tabs-label {
    @include font-style(16, 14, 700);
    color: map-get($colors, neutrals-on-bg);
  }
  &-selected {
    background-color: map-get($primary, primary30);
    .tabs-label {
    color: map-get($primary, primary200);

    }
  }
  &:hover {
    background-color: map-get($primary, primary30);
  }
}

.container-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  gap: rem(20);
  .image {
    border-radius: rem(8);
    width: 32%;
    height: rem(250);
    object-fit: cover;
  }
  .left {
    margin-right: auto;
  }
  .right {
    margin-left: auto;
  }
  .center {
    margin: auto;
  }
}

.close-button {
  position: absolute;
  cursor: pointer;
  top: rem(60);
  left: rem(20);
  font-size: rem(24);
}
.close-button-slider {
  @extend .close-button;
  left: calc(100vw - 50px);
  top: rem(25);
}

.container-aspect-radio {
  width: 80%;
  height: 75vh;
}
.error-url {
  @include font-style(16, 14, 700);

}
