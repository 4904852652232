@import "src/assets/styles/var";

.menu-button {
  background-color: white !important;
}

.menu-button-value {
  @include font-style(16, 16, 700);
}
.menu-button-default {
  @include font-style(16, 16, 300);
}
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.menu-button {
  width: 100%;
}

.select-extra-label {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  white-space: nowrap;
  z-index: 10;
  padding-left: 10px;
}

.container-text-default {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-custom-select-input {
  max-height: 20rem;
  width: 100%;
  margin-top: rem(12);
  margin-bottom: rem(12);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: rem(4);
  }
  .menu-flex-custom-select {
    @include font-style(14, 16, 700);
    display: flex;
    gap: rem(16);
    justify-content: space-between;
    width: 100%;
    font-size: rem(16);
    padding: rem(12) rem(16);
    padding-left: rem(8);
    color: map-get($colors, neutrals-on-bg);
    &:hover {
      cursor: pointer;
      background-color: map-get($primary, primary10);
    }
    &:disabled {
      user-select: none;
      background-color: grey;
    }
    [aria-disabled="true"] {
      background-color: red !important;
    }
  }
}
