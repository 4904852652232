@import "src/assets/styles/var";

.fc-toolbar-title {
  display: inline;
}

.fc-toolbar-chunk {
  div[class=""] {
    display: flex;
    align-items: center;
  }
}

.fc-timegrid-event {
  padding: rem(4) !important;
  background-color: #3b54d4 !important;
  border-radius: rem(8) !important;
  cursor: pointer;
}

.fc-button-primary {
  border: none !important;
  padding: rem(8) rem(33) !important;
  border-top-right-radius: initial !important;
  border-bottom-right-radius: initial !important;
  border-top-left-radius: initial !important;
  border-bottom-left-radius: initial !important;
  border-radius: rem(50) !important;
  background-color: transparent !important;
  color: map-get($primary, primary200) !important;
  font-size: rem(16) !important;
  line-height: rem(24) !important;
  font-weight: 700 !important;
  transition: background-color linear 0.2s;

  &:focus {
    box-shadow: none !important;
  }
}

.fc-button-active {
  @extend .fc-button-primary;
  background-color: map-get($primary, primary30) !important;
}
.fc-prev-button {
  padding: rem(8) !important;
  margin-right: rem(10) !important;
  margin-left: rem(17) !important;
  border-radius: rem(10) !important;
  background-color: map-get($primary, primary10) !important;
  transition: background-color linear 0.2s;
  &:hover {
    background-color: map-get($primary, primary30) !important;
  }
  &:focus {
    box-shadow: none !important;
  }
  border: none !important;
  .fc-icon {
    color: map-get($primary, primary100);
  }
}

.fc-theme-standard {
  td {
    border: none !important;
    border-color: #a1adf5 !important;
  }
  th {
    border: none !important;
  }
}

.fc-toolbar-title {
  min-width: rem(150);
}
.fc-timegrid-slot-label-frame {
  text-align: center !important;
  height: 0% !important;
}

.fc-dayGridMonth-view {
  .fc-daygrid-day-frame  {
    height: rem(105) !important;
  }
  .fc-daygrid-day-events {
    .fc-daygrid-day-bottom {
      position: absolute !important;
      top: rem(47);
      @include truncate();
      width: 97% !important;
    }
  }
  .fc-daygrid-event-harness {
    width: 97% !important;
    position: absolute !important;
  }
  .fc-daygrid-day-events div:nth-child(2) {
    top: rem(25);
  }
  .fc-scrollgrid-sync-inner {
    background-color: map-get($colors, neutrals-bg);
  }

  .fc-daygrid-day-frame {
    border-bottom: 1px solid #a1adf5 !important;
    border-right: 1px solid #a1adf5 !important;
  }
  .fc-scrollgrid-sync-table {
    border-top: 1px solid #a1adf5 !important;
    border-left: 1px solid #a1adf5 !important;
  }
  .fc-day-today div:first-child {
    background-color: #edeffd !important;
  }
}
.fc-timeGridDay-view {
  .fc-scrollgrid-sync-inner,
  .fc-timegrid-axis {
    background-color: map-get($colors, neutrals-bg);
  }
  td .fc-timegrid-slot-minor {
    border-bottom: 1px solid #a1adf5 !important;

  }
  .fc-daygrid-day-events {
    display: none;
  }
  .fc-col-header-cell {
    background-color: transparent !important;
    border-bottom: 1px solid #a1adf5 !important;
  }
  tbody[role="rowgroup"] td:first-child {
    border: none !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    border-bottom: none !important;
  }
  .fc-timegrid-slot-lane {
    border-bottom: none !important;
    background-color: #f6f7fb !important;
  }

  .fc-scrollgrid-shrink {
    border-bottom: none !important;
  }
  .fc-timegrid-slot-minor {
    border-top: none !important;
  }
}
.fc-timeGridWeek-view {
  .fc-scrollgrid-sync-inner,
  .fc-timegrid-axis {
    background-color: map-get($colors, neutrals-bg);
  }
  .fc-col-header-cell {
    background-color: transparent !important;
    border-bottom: 1px solid #a1adf5 !important;
  }
  .fc-timegrid-slot-minor {
    border-top: none !important;
    border-bottom: 1px solid #a1adf5 !important;
  }

  .fc-theme-standard td {
    border-left: none !important;
  }

  tbody[role="rowgroup"]
    tr:first-child
    td:first-child
    .fc-scrollgrid-sync-table {
    display: none !important;
  }
  tbody[role="rowgroup"] td:first-child {
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
  }
}
.fc-timegrid-divider {
  display: none;
}

//   .container-cell {
//     border: 1px solid #a1adf5 !important;
//   }

.fc-daygrid-day-number {
  font-size: rem(12) !important;
  line-height: rem(14.4) !important;
  font-weight: 700 !important;
  color: map-get($colors, neutrals-on-bg) !important;
}

.fc-day-today {
  background-color: map-get($primary, primary10) !important;
}

.fc-daygrid-event {
  cursor: pointer;
  border-radius: rem(4) !important;
  border: none !important;
  background-color: map-get($primary, primary30) !important;
  .fc-daygrid-event-dot {
    background-color: map-get($primary, primary200) !important;
  }
  .fc-event-title,
  .fc-event-time {
    color: map-get($colors, neutrals-on-bg) !important;
    font-size: rem(12) !important;
    font-weight: 700 !important;
  }
}

.fc-col-header-cell {
  border: none !important;
}

.fc-col-header,
.fc-scrollgrid {
  border: none !important;
}

.fc-next-button {
  @extend .fc-prev-button;
  margin: rem(0) !important;
}

.fc-button-group {
  display: flex;
  gap: rem(10);
}

.fc-toolbar-title {
  font-family: "Satoshi" !important;
  font-size: rem(20) !important;
  line-height: rem(16) !important;
  font-weight: 700 !important;
  color: map-get($colors, neutrals-on-bg) !important;
}
.fc-popover-header {
  background-color: white !important;
  margin-bottom: rem(10) !important;
}

.fc-popover-body {
  min-width: inherit !important;
  max-width: rem(180) !important;
  width: 100% !important;
  padding: rem(0) !important;
  .fc-daygrid-event-harness {
    margin-bottom: rem(4) !important;
  }
}

.fc-popover {
  background-color: white !important;
  padding: rem(14) rem(10) !important;
  border-radius: rem(8);

  .fc-popover-title {
    text-transform: capitalize !important;
    @include font-style(12, 14.4, 700);
    color: map-get($colors, neutrals-on-bg);
  }
  .fc-popover-close {
    color: map-get($colors, neutrals-on-bg) !important;
    font-weight: 500 !important;
    opacity: 1 !important;
  }
}
