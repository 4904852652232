@import "src/assets/styles/var";

.form-content {
  .fixed-submit-buttom {
    position: fixed;
    right: rem(36);
    bottom: rem(15);
  }

  .registry,
  .company {
    margin-bottom: rem(24);
    @include font-style(20, 24, 700);
    color: map-get($colors, neutrals-on-bg);
  }

  .company {
    margin-bottom: rem(32);
  }

  .container-submit {
    display: flex;
    justify-content: flex-end;
  }

  .company-container {
    padding-top: rem(4);

    .group-description {
      @include font-style(12, 16, 400);
      color: map-get($colors, neutrals-on-bg);
      margin-top: rem(12);
    }
  }

  .radio-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(44);

    .mat-radio-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .mat-radio-button.mat-accent {
      margin-right: rem(8);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .form-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: rem(40);

    .btn-text {
      cursor: pointer;
      margin-right: rem(20);
      min-width: rem(166);
      text-align: center;
    }
  }

  .no-margin {
    margin-bottom: 0;
  }

  .form-group .group-chip-list .group-wrapper {
    padding-left: 0;
  }
}

.toggle-container {
  display: flex;
  align-items: center;

  .user-info-tooltip {
    padding: 0;
    margin-left: rem(4);
  }

  .icon-info {
    font-size: rem(20);
    color: map-get($colors, neutrals-on-bg);
  }
}

.users-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: rem(50);

  .add-user {
    background-color: darkcyan;
  }
}

.form-control-label {
  @include font-style(12, 18, 700);
  @include truncate();
  color: map-get($colors, neutrals-on-bg);
  min-height: rem(18);
  margin-left: rem(8);
}
