@import "src/assets/styles/var";

.calendar-month-custom-input {
  display: flex;
  gap: rem(8);
  cursor: pointer;

  .icon-arrow-down {
    font-size: rem(16);
  }
  .data-month-label {
    @include font-style(14, 18, 500);
    font-family: "Satoshi";
    color: map-get($colors, neutrals-on-bg);
  }
}
